import React, { Component } from 'react';
import {
  Row, Col,
  Button, ModalBody, Modal,
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-image-lightbox/style.css";
import CustomSpinner from '../CustomSpinner/CustomSpinner';

class ApproveCip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    }
  }

  toggle = (row = {}) => {
    if (!this.state.visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    this.setState({
      visible: !this.state.visible,
      cip_detail: row
    })
  }

  approveCip = (isApprove) => {
    this.props.approveCip(isApprove ? this.state.cip_detail : {});
    this.toggle();
  }

  render() {
    return (
      <Modal
        isOpen={this.state.visible}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: "516px" }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-column">
          <div className="confirm-modal-title-wrapp">
            <span className="confirm-modal-title">Approve CIP</span>
            <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
          </div>
        </div>
        <ModalBody style={{ padding: '1.1rem' }}>
          <Row>
            <Col xl="12" xs="12" md="12" className="mt-2 mb-4">
              <div style={{ fontSize: '18px', width: '330px' }} className="text-align-center ml-auto mr-auto">Are you sure you want to approve this CIP?</div>
            </Col>
            <Col xl="12" md="12" xs="12">
              <div className="d-flex justify-content-center mt-3 mb-2">
                <Button
                  className="mb-1 mr-1 btn-pill action-button profile-cancel-button"
                  onClick={() => this.approveCip(false)}
                >
                  No
                </Button>  &nbsp;&nbsp;
                <Button 
                  className="mb-1 mr-1 btn-pill action-button profile-save-button"
                  onClick={() => this.approveCip(true)}
                  color="warning"
                >
                  Yes
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default ApproveCip;
