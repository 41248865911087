import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MultiStep from "../../CustomComponents/CustomWizard/Wizard";
import { Row, Col } from "reactstrap";
import CipBusinessDetail from "../../components/CipBusinessDetail/CipBusinessDetail";
import CipVehicleDetails from "../../components/CipVehicleDetails/CipVehicleDetails";
import CipListOfAttendees from "./CipListOfAttendees";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
import CustomerIntractionServie from "../../services/CustomerIntractionService";
import ModalService from "../../services/ModalService";
import swal from "sweetalert";
import ConfirmationNextAtendee from "../../components/ConfirmationNextAtendee/ConfirmationNextAtendee";
import CipPictureUpload from "../../components/CipPictureUpload/CipPictureUpload";
import CipExpensesIncurred from "../../components/CipSummaryList/CipExpensesIncurred";
class CipDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compState: 0,
      isLoading: false,
      cip_dealer_deatil: this.props.cip_dealer_deatil
        ? this.props.cip_dealer_deatil
        : {},
      CipBusinessDetailControls: {},
      isDiaplayAllCMP: true,
      is_new_business: true,
      cip_detail: this.props.cip_detail ? this.props.cip_detail : {},
      cip_old_co_attendee_list: [],
      is_sub_dealer: false,
      isRedirectSummary: false,
      steps: [
        { name: "Add Business Details" }
        // Commenting out other tabs
        // { name: "Add Co-Attendee" },
        // { name: "Add Vehicle Details" },
      ],
    };

    if (
      this.state.cip_dealer_deatil &&
      Object.keys(this.state.cip_dealer_deatil).length <= 0
    ) {
      this.state.isRedirectSummary = true;
    }
    if (
      this.state.cip_dealer_deatil &&
      this.state.cip_dealer_deatil.type_of_activity &&
      this.state.cip_dealer_deatil.type_of_activity.toLowerCase() ===
      "sub-dealer meet"
    ) {
      this.state.is_sub_dealer = true;
    }
    if (this.state.cip_detail && this.state.cip_detail.uuid) {
      this.state.steps = [
        { name: "Edit Business Details" }
        // Commenting out other tabs
        // { name: "Edit Co-Attendee" },
        // { name: "Edit Vehicle Details" },
      ];
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.setTabData();
  }

  setTabData = async () => {
    let { steps, is_new_business, cip_detail } = this.state;
    if (this.state.cip_detail && this.state.cip_detail.uuid) {
      cip_detail = this.state.cip_detail;
      await this.getCIPDetailById();
      if (this.state.is_sub_dealer) {
        steps = [
          { name: "Edit Business Details" }
          // Commenting out other tabs
          // { name: "Edit Co-Attendee" },
          // { name: "Edit Counter Potential" },
        ];
      } else {
        steps = [
          { name: "Edit Business Details" }
          // Commenting out other tabs
          // { name: "Edit Co-Attendee" },
          // { name: "Edit Vehicle Details" },
        ];
      }
      is_new_business = false;
    } else {
      if (this.state.is_sub_dealer) {
        steps = [
          { name: "Add Business Details" }
          // Commenting out other tabs
          // { name: "Add Co-Attendee" },
          // { name: "Add Counter Potential" },
        ];
      } else {
        steps = [
          { name: "Add Business Details" }
          // Commenting out other tabs
          // { name: "Add Co-Attendee" },
          // { name: "Add Vehicle Details" },
        ];
      }
      is_new_business = true;
      cip_detail = {};
    }
    await this.setState({
      steps,
      is_new_business,
      cip_detail,
    });
    // Commenting out vehicle tab selection code
    /*if (this.props.select_vehicle_tab) {
      await this.setState({
        compState: 2,
      });
      if (!this.props.is_view_only_dealils) {
        this.refs.refMultiStep.setManualyCompState(2);
      }
    }*/
  };

  getCIPDetailById = async (mobile_number = null) => {
    let reqData = {};
    if (this.state.cip_detail && this.state.cip_detail.uuid) {
      reqData.uuid = this.state.cip_detail.uuid;
    }
    if (mobile_number) {
      reqData = { mobile_number: mobile_number };
    }
    this.setState({ isLoading: true });
    let result = await CustomerIntractionServie.getCIPDetailById(reqData)
      .then(async (data) => {
        this.setState(
          {
            isLoading: false,
          },
          async () => {
            if (data.data.data && Object.keys(data.data.data).length > 0) {
              this.getCoAttendeeList(data.data.data.id);
              await this.refs.refCipBusinessDetail.setData(data.data.data);
              // Commenting out vehicle details
              //this.refs.refCipVehicleDetails.setData(data.data.data);
            } else {
              this.resetData(true);
            }
          }
        );
        return data;
      })
      .catch((e) => {
        this.setState({ isLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        return false;
      });
    return result;
  };

  backToCipSummary = () => {
    window.scroll(0, 0);
    if (this.props.goToCipSummary) {
      this.props.goToCipSummary(this.props.isEndCip ? "CipEnd" : null);
    }
  };

  isLoading = (flag) => {
    this.setState({
      isLoading: flag,
    });
  };

  // Commenting out vehicle related methods
  /*goToAddVehicle = (controls) => {
    this.setState({
      CipBusinessDetailControls: controls,
    });
    this.refs.refMultiStep.next();
  };*/

  submitDetailBusinessDetail = async (controls) => {
    await this.setState({
      CipBusinessDetailControls: controls,
    });
    if (!this.refs.refCipBusinessDetail.handleValidation(true)) {
      return;
    }
    this.submitBusinessAndVehicleDetails(false, true, true);
  };

  stepNoValidation = async (step) => {
    if (step === 0) {
      if (!(await this.refs.refCipBusinessDetail.handleValidation(true))) {
        return false;
      }
      await this.setState({
        CipBusinessDetailControls: this.refs.refCipBusinessDetail.state
          .controls,
      });
      let result = await this.submitBusinessAndVehicleDetails(
        false,
        true,
        false
      );
      return result;
    }
    // Commenting out other tab validations
    /*else if (step === 1) {
      return true;
    } else if (step === 2) {
      return true;
    }*/
    return false;
  };

  // Commenting out vehicle related methods
  /*getReqFrontData = () => {
    const { frontRearData } = this.refs.refCipVehicleDetails.state.controls;
    let resFrontData = {
      bus: [],
      truck: [],
      lcv: [],
      scv: [],
    };
    Object.keys(frontRearData).forEach((key, i) => {
      frontRearData[key].forEach((element) => {
        if (element.tyres.value) {
          resFrontData[key].push({
            construction: element.construction.value,
            front_or_rear: element.front_or_rear.value,
            size: element.size.value,
            brand: element.brand.value,
            pattern: element.pattern.value,
            tyres: element.tyres.value ? element.tyres.value : null,
            brand_id: element.brand_id.value ? element.brand_id.value : null,
          });
        }
      });
    });
    return resFrontData;
  };*/

  resetDataFromMobileNo = async () => {
    let mobile_number = this.refs.refCipBusinessDetail.state.controls
      .mobile_number;
    await this.setState(
      {
        isDiaplayAllCMP: false,
        compState: 0,
        CipBusinessDetailControls: {},
        cip_detail: {},
      },
      () => {
        this.setState({
          isDiaplayAllCMP: true,
        });
        this.setTabData();
      }
    );
    let CipBusinessDetailControls = this.refs.refCipBusinessDetail.state
      .controls;
    CipBusinessDetailControls.mobile_number = mobile_number;
    await this.setState({
      CipBusinessDetailControls,
    });
    this.refs.refCipBusinessDetail.setControls();
  };

  resetData = (isNotResetMobile = false) => {
    let mobile_number = "";
    if (isNotResetMobile) {
      const { refCipBusinessDetail } = this.refs;
      if (
        refCipBusinessDetail &&
        refCipBusinessDetail.state &&
        refCipBusinessDetail.state.controls
      ) {
        mobile_number = refCipBusinessDetail.state.controls.mobile_number.value;
      }
    }
    this.setState(
      {
        isDiaplayAllCMP: false,
        compState: 0,
        CipBusinessDetailControls: {},
        cip_detail: {},
        is_new_business: true,
      },
      () => {
        this.setState(
          {
            isDiaplayAllCMP: true,
          },
          () => {
            if (mobile_number) {
              this.refs.refCipBusinessDetail.setMobileNo(mobile_number);
            }
          }
        );
        this.setTabData();
      }
    );
  };

  // Commenting out attendee related methods
  /*confirmationNextAtendee = () => {
    if (this.state.is_sub_dealer) {
      if (
        !this.refs.refCipVehicleDetails.handleValidationVehicleRelatedDetailsCounterPotential(
          null,
          null,
          null,
          true
        )
      ) {
        return false;
      }
    } else {
      if (
        !this.refs.refCipVehicleDetails.handleValidationVehicleRelatedDetails(
          null,
          null,
          null,
          true
        )
      ) {
        return false;
      }
    }

    if (!this.props.is_sub_dealer) {
      if (!this.refs.refCipVehicleDetails.checkAllDataValidationFront()) {
        return false;
      }
      if (!this.refs.refCipVehicleDetails.checkAllDataValidationRear()) {
        return false;
      }
    }

    if (!this.state.is_new_business) {
      this.submitBusinessAndVehicleDetails(false, false, false);
      return;
    }
    this.refs.refConfirmationNextAtendee.toggle();
  };*/

  submitBusinessAndVehicleDetails = async (
    isNextAttende = false,
    isFromBusinessDetails = false,
    is_go_to_next_step = false
  ) => {
    let controls = this.refs.refCipBusinessDetail.state.controls;
    let reqData = {
      company_name: controls.company_name.value,
      attendee_name: controls.attendee_name.value,
      designation: controls.designation.value,
      mobile_number: controls.mobile_number.value,
      whatsapp_number: controls.whatsapp_number.value
        ? controls.whatsapp_number.value
        : null,
      email: controls.email.value,
      state_id: controls.state.value,
      city_id: controls.city.value,
      pin_code: controls.pin_code.value,
      address: controls.address.value,
    };

    // Commenting out vehicle related data
    /*let vehicleData = this.refs.refCipVehicleDetails.state.vehicleReleatedData;
    if (Object.keys(vehicleData).length > 0) {
      reqData.vehicle_detail = {};
      Object.keys(vehicleData).forEach((key) => {
        reqData.vehicle_detail[key] = [];
        reqData.counter_potential_detail = [];
        vehicleData[key].data.forEach((element) => {
          if (element.hasOwnProperty("type")) {
            if (key === "counter_potential_detail") {
              reqData.counter_potential_detail.push({ type: element["type"] });
            } else {
              reqData.vehicle_detail[key].push({ type: element["type"] });
            }
          }
          if (element.hasOwnProperty("label")) {
            if (key === "counter_potential_detail") {
              reqData.counter_potential_detail[
                reqData.counter_potential_detail.length - 1
              ].label = element["label"];
            } else {
              reqData.vehicle_detail[key][
                reqData.vehicle_detail[key].length - 1
              ].label = element["label"];
            }
          }
          if (element.hasOwnProperty("count")) {
            if (key === "counter_potential_detail") {
              reqData.counter_potential_detail[
                reqData.counter_potential_detail.length - 1
              ].count = element["count"].value
                  ? Number(element["count"].value)
                  : null;
            } else {
              reqData.vehicle_detail[key][
                reqData.vehicle_detail[key].length - 1
              ].count = element["count"].value
                  ? Number(element["count"].value)
                  : null;
            }
          }
          if (element.hasOwnProperty("application")) {
            reqData.vehicle_detail[key][
              reqData.vehicle_detail[key].length - 1
            ].application = element["application"].value;
          }
          if (element.hasOwnProperty("payload")) {
            reqData.vehicle_detail[key][
              reqData.vehicle_detail[key].length - 1
            ].payload = element["payload"].value
                ? Number(element["payload"].value)
                : null;
          }
          if (element.hasOwnProperty("average_monthly_usage")) {
            reqData.vehicle_detail[key][
              reqData.vehicle_detail[key].length - 1
            ].average_monthly_usage = element["average_monthly_usage"].value;
          }
        });
      });
      reqData.business_related_detail = this.getReqFrontData();
    }*/

    if (this.state.is_sub_dealer) {
      reqData.vehicle_detail = {};
    } else {
      reqData.counter_potential_detail = [];
    }
    if (this.state.cip_dealer_deatil && this.state.cip_dealer_deatil.id) {
      reqData.cip_id = this.state.cip_dealer_deatil.id;
    }

    if (this.state.cip_detail && this.state.cip_detail.uuid) {
      reqData.uuid = this.state.cip_detail.uuid;
      let result = await this.updateCIPDetail(
        reqData,
        isFromBusinessDetails,
        is_go_to_next_step,
        isNextAttende
      );
     
      // return result;
      swal({
        title: "Success",
        text: "Customer details updated successfully.",
        icon: "success",
        type: "success",
      }).then(() => {
        this.setState({cip_dealer_deatil : {}})
        this.backToCipSummary();
      })
      return result;
    }

    this.setState({ isLoading: true });
    CustomerIntractionServie.addCipDetailsV2(this.props.isEndCip ? {...reqData , invitation_type : "joined"}  :  reqData)
      .then((res) => {
        if (!isFromBusinessDetails) {
          swal({
            title: "",
            text: res.data.message,
            icon: "success",
            type: "success",
          }).then((value) => { });
          if (isNextAttende) {
            this.resetData();
          } else {
            this.backToCipSummary();
          }
        } else {
          this.setState(
            {
              cip_detail: res.data.data,
              isLoading: false,
            },
            () => {
              if (
                this.state.cip_old_co_attendee_list &&
                this.state.cip_old_co_attendee_list.length > 0
              ) {
                this.addCOAttendee();
              }
            }
          );
          if (is_go_to_next_step) {
            this.refs.refMultiStep.next();
          }
        }

        swal({
          title: "Success",
          text: "Customer details added successfully.",
          icon: "success",
          type: "success",
        }).then(() => {

          this.setState({ isLoading: false });
          this.backToCipSummary();
        })

      })
      .catch((e) => {
        this.setState({ isLoading: false });
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
      });
  };

  updateCIPDetail = async (
    reqData,
    isFromBusinessDetails,
    is_go_to_next_step,
    isNextAttende = false
  ) => {
    this.setState({ isLoading: true });
    let result = await CustomerIntractionServie.updateCIPDetailV2(reqData)
      .then(async (res) => {
        await this.setState({ isLoading: false });
        if (!isFromBusinessDetails) {
          if (!this.state.is_new_business) {
            swal({
              title: "",
              text: res.data.message,
              icon: "success",
              type: "success",
            }).then((value) => {
              this.backToCipSummary(true);
            });
          }
          this.resetData();
        } else {
          if (is_go_to_next_step) {
            this.refs.refMultiStep.next();
          }
          if (
            this.state.cip_old_co_attendee_list &&
            this.state.cip_old_co_attendee_list.length > 0
          ) {
            this.addCOAttendee();
          }
        }
        return true;
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
        return false;
      });
    return result;
  };

  // Commenting out co-attendee related methods
  /*nextBtnCoAttendee = () => {
    this.refs.refMultiStep.next();
  };*/

  getCoAttendeeList = (id) => {
    let reqData = {
      cip_detail_id: id,
      page: 1,
      pagesize: 200,
    };
    this.setState({
      isLoading: true,
    });
    CustomerIntractionServie.getCipCOAttendeeSummary(reqData)
      .then((data) => {
        let { cip_old_co_attendee_list } = this.state;
        cip_old_co_attendee_list = data.data.data.data;
        this.setState({
          cip_old_co_attendee_list,
          isLoading: false,
        });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  addCOAttendee = () => {
    let reqData = {
      cip_detail_id: this.state.cip_detail.id,
      attendee: this.state.cip_old_co_attendee_list,
    };

    this.setState({ isLoading: true });
    CustomerIntractionServie.addCOAttendee(reqData)
      .then((res) => {
        this.setState({
          isLoading: false,
          cip_old_co_attendee_list: [],
        });
        this.refs.refCipListOfAttendees.init();
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  onChangeStep = async (compState) => {
    await this.setState({
      compState: compState,
    });
    if (compState === 1 && this.state.cip_old_co_attendee_list.length <= 0) {
      this.refs.refCipListOfAttendees.init();
    }
  };

  // Commenting out confirmation methods
  /*submitConfirmationNextAtendee = (flag) => {
    if (flag) {
    } else {
      this.submitBusinessAndVehicleDetails(true, false, false);
    }
  };*/

  openExpensesIncurred = (row) => {
    this.refs.refCipExpensesIncurred.toggle(this.state.cip_dealer_deatil);
  };

  openPictureUpload = () => {
    this.refs.refCipPictureUpload.toggle(this.state.cip_dealer_deatil);
  };

  redirectSummary = () => {
    this.props.redirectToCIPSummaryList();
  };

  openConfirmationNextAtendee = () => {
    this.refs.refConfirmationNextAtendee.toggle();
  };

  render() {

    const isEditData = this.state.cip_detail && this.state.cip_detail.id
      ? true
      : false;

    return (
      <div>
        {this.state.isRedirectSummary && (
          <Redirect
            to={{
              pathname: "/cip-summary",
            }}
          />
        )}
        {this.state.isLoading && <CustomSpinner />}
        <Row>
          <Col xl="12" md="12" xs="12" className="mb-2">
            <div className="d-flex-row item-center">
              <img
                src="images/right.svg"
                alt=""
                style={{ transform: "rotate(180deg)" }}
                onClick={() => this.backToCipSummary()}
              />
              &nbsp;&nbsp;
              <span
                onClick={() => this.backToCipSummary()}
                className="Merchandise-catalogu-link cursor-pointer"
                style={{ fontSize: "16px" }}
              >
                Back
              </span>
              &nbsp;&nbsp;



           
            </div>
          </Col>

          <Col xl="12" md="12" xs="12" className="mb-2"> 
              <p
                // className="cursor-pointer"
                style={{ fontSize: "26px" }}
              >
                { isEditData ? "Edit Customer" : "Add Customer"}
              </p>
          </Col>

         

          {this.state.isDiaplayAllCMP && !this.props.is_view_only_dealils && (
            <Col xl="6" md="12" xs="12" style={{ marginLeft: "-1%" }}>
              <MultiStep
                ref={"refMultiStep"}
                showNavigation={true}
                steps={this.state.steps}
                compState={0}
                isValidateAllSteps={true}
                stepNoValidation={this.stepNoValidation}
                setCompState={(compState) => this.onChangeStep(compState)}
              />
            </Col>
          )}
        </Row>
        {this.state.isDiaplayAllCMP && (
          <div>
            {
              <div className={this.state.compState !== 0 ? "hidden" : ""}>
                <Row>
                  <Col xl="9" md="12" xs="12">
                    <CipBusinessDetail
                      ref={"refCipBusinessDetail"}
                      submitDetailBusinessDetail={
                        this.submitDetailBusinessDetail
                      }
                      CipBusinessDetailControls={
                        this.state.CipBusinessDetailControls
                      }
                      cip_uuid={this.props.cip_id}
                      getCoAttendeeList={this.getCoAttendeeList}
                      is_new_business={this.state.is_new_business}
                      resetDataFromMobileNo={this.resetDataFromMobileNo}
                      isLoading={this.isLoading}
                      getCIPDetailById={this.getCIPDetailById}
                      isEditData={isEditData}
                    />
                  </Col>
                </Row>
              </div>
            }
            {/* Commenting out other tabs
            {
              <div className={this.state.compState !== 1 ? "hidden" : ""}>
                <Col xl="9" md="12" xs="12" className="mt-2">
                  <CipListOfAttendees
                    ref="refCipListOfAttendees"
                    cip_detail={this.state.cip_detail}
                    cip_old_co_attendee_list={
                      this.state.cip_old_co_attendee_list
                    }
                    nextBtnCoAttendee={this.nextBtnCoAttendee}
                  />
                </Col>
              </div>
            }
            {
              <div className={this.state.compState !== 2 ? "hidden" : ""}>
                <Row>
                  <Col xl="12" md="12" xs="12">
                    <CipVehicleDetails
                      ref={"refCipVehicleDetails"}
                      is_sub_dealer={this.state.is_sub_dealer}
                      cip_detail={this.state.cip_detail}
                      is_new_business={this.state.is_new_business}
                      is_view_only_dealils={this.props.is_view_only_dealils}
                      submitDetail={this.confirmationNextAtendee}
                    />
                  </Col>
                </Row>
              </div>
            }*/}
          </div>
        )}
        <ConfirmationNextAtendee
          ref="refConfirmationNextAtendee"
          openPictureUpload={this.openPictureUpload}
          submitDetail={this.submitBusinessAndVehicleDetails}
          openConfirmationNextAtendee={this.openConfirmationNextAtendee}
          closeConfirmationNextAtendee={this.redirectSummary}
          from="CipDetail"
        />

        <CipPictureUpload
          from="CipDetail"
          openExpensesIncurred={this.openExpensesIncurred}
          redirectSummary={this.redirectSummary}
          ref="refCipPictureUpload"
        />

        <CipExpensesIncurred
          from="CipDetail"
          redirectSummary={this.redirectSummary}
          ref="refCipExpensesIncurred"
        />
      </div>
    );
  }
}

export default CipDetail;
