import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { Nav } from "reactstrap";

// services
import MetisMenu from "react-metismenu";
import Storage from "../../services/Storage";
import AuthService from "../../services/AuthService";
import DashboardService from "../../services/DashboardService";
import DownloadScorecard from "../../components/DownloadScoreCard/DownloadScoreCard";
import RedeemCoupon from "../../components/RedeemCoupon/RedeemCoupon";

// modal
// import ConfirmAlert from "../../modal/ConfirmAlert/ConfirmAlert";
import LogoutModal from "../../modal/LogoutModal/LogoutModal";

// css
import "./AppSidebar.css";

// action
import { setEnableMobileMenu, updateSideMenu } from "../../actions/ThemeAction";
import {
  setUserDetail,
  setAddCustomerRedirect,
  setRedirect,
} from "../../actions/UserAction";
import CustomSpinner from "../../components/CustomSpinner/CustomSpinner";
// utils
import { downlodFile } from "../../utils/utils";

// constant
// import { messages } from "../../constants/constant.message";
import { Permissions } from "../../constants/constant.permissions";
import { Roles } from "../../constants/constant.role";
// import RouterLink from "react-metismenu-router-link";

let modalButtons = [];
// const ExceptionalPointUploadUsers = [
//   "kanikaa@jkmail.com",
//   "bharat@jkmail.com",
//   "rakesh@jkmail.com",
// ];
let menusToShow = [];
let active_id = 0;
const headOfficeMenu = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty, Trade Scheme, Advantage Points",
    id: "0",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: " Offtake Dashboard",
        to: "/dashboard",
        name: "dashboard",
        id: "-1",
        permission: [Permissions.OFFTAKE_DASHBOARD],
      },
      {
        label: "Points Dashboard",
        to: "/point-dashboard",
        name: "dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Offtake Report",
        name: "offtake_report",
        to: "/offtake-score-card-report",
        id: "-1",
        permission: [Permissions.OFFTAKE_REPORT],
      },
      {
        label: "Warranty Report",
        name: "warranty_report",
        to: "/warranty-score-card-report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
      },
      {
        label: "Trade Scheme Report",
        name: "trade_scheme_report",
        to: "/trade-scheme-report",
        id: "-1",
        permission: [Permissions.TRADE_SCHEME_REPORT],
      },
      {
        label: "Download Score Card",
        name: "download_report",
        to: null,
        id: "-1",
        permission: [Permissions.DOWNLOAD_SCORECARD],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/campaigns_icon.png",
    selectedImage: "images/sidebar-icons/campaigns_icn.png",
    label: "Campaigns",
    link_note: "Create, edit, delete and schedule campaigns",
    id: "1",
    name: "campaign",
    permission: [Permissions.CAMPAIGN],
    content: [
      {
        label: "Create Campaign",
        to: "/campaign/create",
        name: "create_campaign",
        id: "-1",
        permission: [Permissions.CREATE_CAMPAIGN],
      },
      {
        label: "Schedule Campaign",
        to: "/campaign/schedule",
        name: "schedule_camapaign",
        id: "-1",
        permission: [Permissions.SCHEDULE_CAMPAIGN],
      },
      {
        label: "Campaign History",
        to: "/campaign/history",
        name: "campaign_history",
        id: "-1",
        permission: [Permissions.CAMPAIGN_HISTORY],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/marketing-coupon.svg",
    selectedImage: "images/sidebar-icons/marketing-coupon-selected.svg",
    label: "Marketing Coupon",
    link_note: "Generate Coupon, Report",
    id: "2",
    name: "marketing_coupon",
    permission: [Permissions.MARKETING_COUPON],
    content: [
      {
        label: "Generate Coupon",
        to: "/marketing-coupon",
        name: "marketing_coupon",
        id: "-1",
        permission: [Permissions.GENERATE_MARKETING_COUPON],
      },
      {
        label: "Report",
        to: "/marketing-coupon",
        name: "marketing_coupon",
        id: "-1",
        permission: [Permissions.MARKETING_COUPON_REPORT],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    label: "Special Approvals",
    link_note: "Approve Special Points & CN",
    id: "3",
    to: "/special-approvals",
    name: "special_approvals",
    permission: [Permissions.SPECIAL_POINTS_UPLOAD],
  },
  {
    label: "Proposal Dashboard",
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    link_note: "New Brand Store & PTP Registration",
    to: "/proposal-dashboard",
    name: "proposal_dashboard",
    id: "4",
    permission: [Permissions.PROPOSAL_DASHBOARD],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "5",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
  {
    label: "Retail Marketing System",
    defaultImage: "images/sidebar-icons/RetailMgtSystemm.png",
    selectedImage: "images/sidebar-icons/RetailMgtSystemSelected.svg",
    link_note: "View Recce, Recce Approval",
    // to: '/retail-marketing-recce-list',
    to: "/retail-marketing-system-list",
    name: "retail_marketing_recce_list",
    id: "6",
    permission: [Permissions.RETAIL_BRANDING_LIST],
    imageIconCustomCss: {
      height: "20px",
      width: "25px",
    },
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Redemption Catalogue",
    link_note: "Gift Catalogue for Advantage points redemption",
    id: "7",
    to: "/redemption-catalog",
    name: "employee_redemption_catalogue",
    permission: [Permissions.REDEMPTION_CATALOGUE],
  },
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "Account",
    link_note: "Orders",
    id: "8",
    name: "ho_account",
    permission: [Permissions.ACCOUNT],
    content: [
      {
        label: "Order History",
        to: "/order-history-ho",
        name: "order_history",
        id: "-1",
        permission: [Permissions.ORDER_HISTORY],
      },
    ],
  },
  {
    defaultImage: "images/location_con_grey.png",
    selectedImage: "images/location_icon_yellow.png",
    label: "Brandstore Locator",
    link_note: "Brandstore Locator",
    id: "9",
    to: "/brandstore-locator",
    name: "MAP_LOCATION",
    permission: [Permissions.MAP_LOCATION],
  },
  {
    to: "/generative_ai",
    defaultImage: "images/sidebar-icons/bot.png",
    selectedImage: "images/sidebar-icons/bot1.png",
    name: "generative_ai",
    label: "Generative AI",
    id: "10",
    permission: [Permissions.GENERATIVE_AI],
    content: [
      {
        to: "/generative_ai",
        label: "Generative AI",
        name: "generative_ai",
        id: "-1",
        permission: [Permissions.GENERATIVE_AI],
      },
      {
        to: "/generative_ai_dashboard",
        label: "Generative AI Dashboard",
        name: "generative_ai_dashboard",
        id: "-1",
        permission: [Permissions.GENERATIVE_AI],
      },
    ],
  },
  {
    to: "/analysis",
    defaultImage: "images/sidebar-icons/analytics1.png",
    selectedImage: "images/sidebar-icons/analytics.png",
    name: "analysis",
    label: "Analysis",
    id: "11",
    permission: [Permissions.JK_ANALYSIS],
  },
  {
    label: "Contact Us",
    defaultImage: "images/sidebar-icons/contact_icon.png",
    selectedImage: "images/sidebar-icons/contact_icon_copy.png",
    link_note: "Office Address",
    to: "/contact-us",
    name: "contact_us",
    permission: [Permissions.CONTACT_US],
    id: "12",
  },
];

const employeeSideMenus = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty, Trade Scheme, Advantage Points",
    id: "0",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: "Offtake Dashboard",
        name: "dashboard",
        to: "/dashboard",
        id: "-1",
        permission: [Permissions.OFFTAKE_DASHBOARD],
      },
      {
        label: "Points Dashboard",
        name: "point_dashboard",
        to: "/point-dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Offtake Report",
        namw: "offtake_report",
        to: "/offtake-score-card-report",
        id: "-1",
        permission: [Permissions.OFFTAKE_REPORT],
      },
      {
        label: "Warranty Report",
        name: "warranty_report",
        to: "/warranty-score-card-report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
      },
      {
        label: "Trade Scheme Report",
        name: "trade_scheme_report",
        to: "/trade-scheme-report",
        id: "-1",
        permission: [Permissions.TRADE_SCHEME_REPORT],
      },
      {
        label: "Download Score Card",
        name: "download_scorecard",
        to: null,
        id: "-1",
        permission: [Permissions.DOWNLOAD_SCORECARD],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    label: "Proposal Dashboard",
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    link_note: "New Brand Store & PTP Registration",
    to: "/proposal-dashboard",
    name: "proposal_dashboard",
    id: "1",
    permission: [Permissions.PROPOSAL_DASHBOARD],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION],
    // content: [
    //   {
    //     label: "CIP Registration",
    //     name: "CIP_Registration",
    //     to: "/cip-registration",
    //     id: "-1",
    //     permission: [Permissions.CUSTOMER_INTERACTION]
    //   },
    //   {
    //     label: "CIP Summary",
    //     name: "CIP_Registration",
    //     to: "/cip-summary",
    //     id: "-1",
    //     permission: [Permissions.CUSTOMER_INTERACTION]
    //   },
    // ]
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Redemption Catalogue",
    link_note: "Gift Catalogue for Advantage points redemption",
    id: "3",
    to: "/redemption-catalog",
    name: "employee_redemption_catalogue",
    permission: [Permissions.REDEMPTION_CATALOGUE],
  },
  {
    label: "Contact Us",
    defaultImage: "images/sidebar-icons/contact_icon.png",
    selectedImage: "images/sidebar-icons/contact_icon_copy.png",
    link_note: "Office Address",
    to: "/contact-us",
    name: "contact_us",
    id: "4",
    permission: [Permissions.CONTACT_US],
  },
];

const salesOfficerSideMenus = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty & Advantage Points",
    id: "0",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: "Points Dashboard",
        name: "point_dashboard",
        to: "/point-dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Offtake Report",
        name: "offtake_report",
        to: "/offtake-score-card-report",
        id: "-1",
        permission: [Permissions.OFFTAKE_REPORT],
      },
      {
        label: "Warranty Report",
        name: "warranty_report",
        to: "/warranty-score-card-report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    to: "/cip-summary",
    name: "cip",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION],
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Redemption Catalogue",
    link_note: "Gift Catalogue for Advantage points redemption",
    id: "2",
    to: "/redemption-catalog",
    name: "employee_redemption_catalogue",
    permission: [Permissions.REDEMPTION_CATALOGUE],
  },
  {
    label: "Contact Us",
    defaultImage: "images/sidebar-icons/contact_icon.png",
    selectedImage: "images/sidebar-icons/contact_icon_copy.png",
    link_note: "Office Address",
    to: "/contact-us",
    name: "contact_us",
    id: "3",
    permission: [Permissions.CONTACT_US],
  },
];

const allOtherRoles = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty, Trade Scheme, Advantage Points ",
    id: "0",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: " Offtake Dashboard",
        name: "dashboard",
        to: "/dashboard",
        id: "-1",
        permission: [Permissions.OFFTAKE_DASHBOARD],
      },
      {
        label: "Points Dashboard",
        name: "point_dashboard",
        to: "/point-dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Offtake Report",
        name: "offtake_report",
        to: "/offtake-score-card-report",
        id: "-1",
        permission: [Permissions.OFFTAKE_REPORT],
      },
      {
        label: "Warranty Report",
        name: "warranty_report",
        to: "/warranty-score-card-report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
      },
      {
        label: "Trade Scheme Report",
        name: "trade_scheme_report",
        to: "/trade-scheme-report",
        id: "-1",
        permission: [Permissions.TRADE_SCHEME_REPORT],
      },
      {
        label: "Download Score Card",
        name: "download_scorecard",
        to: null,
        id: "-1",
        permission: [Permissions.DOWNLOAD_SCORECARD],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    label: "Proposal Dashboard",
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    link_note: "New Brand Store & PTP Registration",
    to: "/proposal-dashboard",
    name: "proposal_dashboard",
    id: "1",
    permission: [Permissions.PROPOSAL_DASHBOARD],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Redemption Catalogue",
    link_note: "Gift Catalogue for Advantage points redemption",
    id: "3",
    to: "/redemption-catalog",
    name: "employee_redemption_catalogue",
    permission: [Permissions.REDEMPTION_CATALOGUE],
  },
  {
    label: "Retail Marketing System",
    defaultImage: "images/sidebar-icons/RetailMgtSystemm.png",
    selectedImage: "images/sidebar-icons/RetailMgtSystemSelected.svg",
    link_note: "View Recce, Recce Approval",
    to: "/retail-marketing-system-list",
    name: "retail_marketing_recce_list",
    id: "4",
    permission: [Permissions.RETAIL_BRANDING_LIST],
    imageIconCustomCss: {
      height: "20px",
      width: "25px",
    },
  },
  {
    label: "Contact Us",
    defaultImage: "images/sidebar-icons/contact_icon.png",
    selectedImage: "images/sidebar-icons/contact_icon_copy.png",
    link_note: "Office Address",
    to: "/contact-us",
    name: "contact_us",
    id: "5",
    permission: [Permissions.CONTACT_US],
  },
];

const pmMenu = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty & Advantage Points",
    id: "0",
    name: "offtake_points",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: " Offtake Dashboard",
        to: "/dashboard",
        name: "dashboard",
        id: "-1",
        permission: [Permissions.OFFTAKE_DASHBOARD],
      },
      {
        label: "Points Dashboard",
        to: "/point-dashboard",
        name: "points_dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Warranty Report",
        name: "warranty_report",
        to: "/warranty-score-card-report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT_EMPLOYEE],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
  {
    defaultImage: "images/sidebar-icons/campaigns_icon.png",
    selectedImage: "images/sidebar-icons/campaigns_icn.png",
    label: "Campaigns",
    link_note: "Create, edit, delete and schedule campaigns",
    id: "2",
    name: "campaign",
    permission: [Permissions.CAMPAIGN],
    content: [
      {
        label: "Create Campaign",
        to: "/campaign/create",
        name: "create_campaign",
        id: "-1",
        permission: [Permissions.CREATE_CAMPAIGN],
      },
      {
        label: "Schedule Campaign",
        to: "/campaign/schedule",
        name: "schedule_camapaign",
        id: "-1",
        permission: [Permissions.SCHEDULE_CAMPAIGN],
      },
      {
        label: "Campaign History",
        to: "/campaign/history",
        name: "campaign_history",
        id: "-1",
        permission: [Permissions.CAMPAIGN_HISTORY],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    label: "Special Approvals",
    link_note: "Approve Special Points & CN",
    id: "3",
    to: "/special-approvals",
    name: "special_approvals",
    permission: [Permissions.SPECIAL_POINTS_UPLOAD],
  },
  {
    to: "/exceptional-points-upload",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Exceptional Points Upload",
    link_note: "Scheme Or Approval Points",
    id: "4",
    permission: [Permissions.EXCEPTIONAL_POINT_UPLOAD],
    name: "exceptional_points_upload",
  },
  {
    label: "Retail Marketing System",
    defaultImage: "images/sidebar-icons/RetailMgtSystemm.png",
    selectedImage: "images/sidebar-icons/RetailMgtSystemSelected.svg",
    link_note: "View Recce, Recce Approval",
    // to: '/retail-marketing-recce-list',
    to: "/retail-marketing-system-list",
    name: "retail_marketing_recce_list",
    id: "5",
    permission: [Permissions.RETAIL_BRANDING_LIST],
    imageIconCustomCss: {
      height: "20px",
      width: "25px",
    },
  },
  {
    defaultImage: "images/sidebar-icons/advertising_banner_not_selected.png",
    selectedImage: "images/sidebar-icons/advertising-banner-selected.png",
    label: "Advertising Banners",
    link_note: "Create and Edit Banners",
    id: "6",
    to: "/banner-list",
    name: "advertising_banners",
    permission: [Permissions.BANNER_LIST],
    // content: [
    //   {
    //     label: "Create Campaign",
    //     to: "/campaign/create",
    //     name: "create_campaign",
    //     id: "-1",
    //   }
  },
  {
    defaultImage: "images/sidebar-icons/Poster_icn_1.png",
    selectedImage: "images/sidebar-icons/Poster_icon_1.png",
    label: "Create Poster",
    link_note: "Create and edit poster",
    id: "7",
    to: "/poster-list",
    name: "poster_list",
    permission: [Permissions.CREATE_POSTER],
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Redemption Catalogue",
    link_note: "Gift Catalogue for Advantage points redemption",
    id: "8",
    to: "/redemption-catalog",
    name: "employee_redemption_catalogue",
    permission: [Permissions.REDEMPTION_CATALOGUE],
  },
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "Admin Functionality",
    // link_note: "User update password to default and etc",
    link_note: "Advantage / Baadshah",
    id: "9",
    to: "/user-updates",
    name: "user_status_change",
    permission: [Permissions.USER_STATUS_CHANGE],
  },
  {
    defaultImage: "images/location_con_grey.png",
    selectedImage: "images/location_icon_yellow.png",
    label: "Brandstore Locator",
    link_note: "Brandstore Locator",
    id: "10",
    to: "/brandstore-locator",
    name: "MAP_LOCATION",
    permission: [Permissions.MAP_LOCATION],
  },
];

const financeMenu = [
  {
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    label: "Special Approvals",
    link_note: "Approve Special Points & CN",
    id: "0",
    to: "/special-approvals",
    name: "special_approvals",
    permission: [Permissions.SPECIAL_POINTS_UPLOAD],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const accountantMenu = [
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "Account",
    link_note: "Orders",
    id: "0",
    name: "account",
    permission: [Permissions.ACCOUNT],
    content: [
      {
        label: "Order History",
        to: "/order-history-ho",
        name: "order_history",
        id: "-1",
        permission: [Permissions.ORDER_HISTORY],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const systemArchitectMenu = [
  {
    label: "Proposal Dashboard",
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    link_note: "New Brand Store Registration",
    to: "/proposal-dashboard",
    name: "proposal_dashboard",
    id: "0",
    permission: [Permissions.PROPOSAL_DASHBOARD],
  },
  {
    to: "/proposal-assign",
    defaultImage: "images/sidebar-icons/icon-Architect.svg",
    selectedImage: "images/sidebar-icons/icon-Architect-selected.svg",
    link_note: "Proposal Assign",
    label: "Proposal Assign",
    name: "proposal_assign",
    isChangeWidth: true,
    id: "1",
    isActive: false,
    key: "Proposal Assign",
    permission: [Permissions.PROPOSAL_ASSIGN],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const hyMenu = [
  {
    label: "Warranty Report",
    defaultImage: "images/sidebar-icons/secondary-report.svg",
    selectedImage: "images/sidebar-icons/secondary-report-selected.svg",
    to: "/warranty-score-card",
    name: "warranty_report",
    id: "0",
    permission: [Permissions.WARRANTY_REPORT],
  },
  {
    defaultImage: "images/sidebar-icons/redeem_coupon.svg",
    selectedImage: "images/sidebar-icons/redeem_coupon_selected.svg",
    label: "Redeem Marketing Coupon",
    link_note: "Redeem Discount Coupon,View Report",
    id: "1",
    name: "redeem_marketing_coupon",
    permission: [Permissions.REDEEM_MARKETING_COUPON],
    content: [
      {
        label: "Redeem Discount Coupon",
        to: null,
        name: "redeem_marketing_coupon",
        id: "-1",
        permission: [Permissions.REDEEM_MARKETING_DISCOUNT_COUPON],
      },
      {
        label: "View Report",
        to: "/redeem-coupon-report",
        name: "redeem_coupon_report",
        id: "-1",
        permission: [Permissions.MARKETING_COUPON_REDEEM_REPORT],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const advantageSideMenus = [
  {
    label: "Offtake and Points",
    defaultImage: "images/sidebar-icons/offtake_icon.png",
    selectedImage: "images/sidebar-icons/whatsnew-selected.svg",
    link_note: "Offtake, Warranty, Trade Scheme, Advantage Points",
    id: "0",
    name: "offtake_points",
    permission: [Permissions.OFFTAKE_AND_POINTS],
    content: [
      {
        label: " Offtake Dashboard",
        name: "dashboard",
        to: "/dealer-dashboard",
        id: "-1",
        permission: [Permissions.DEALER_NEW_DASHBOARD],
      },
      {
        label: "Points Dashboard",
        to: "/",
        name: "points_dashboard",
        id: "-1",
        permission: [Permissions.POINTS_DASHBOARD],
      },
      {
        label: "Point Earn Report",
        to: "/primary-points-report",
        name: "reports",
        id: "-1",
        permission: [Permissions.POINT_EARN_REPORT],
      },
      {
        label: "Trade Scheme",
        to: "/trade-scheme",
        name: "trade_scheme",
        id: "-1",
        permission: [Permissions.TRADE_SCHEME],
      },
      {
        label: "Warranty Report",
        to: "/warranty-score-card",
        name: "warranty_report",
        id: "-1",
        permission: [Permissions.WARRANTY_REPORT],
      },
      {
        label: "Download Score Card",
        name: "download_scorecard",
        to: null,
        id: "-1",
        permission: [Permissions.DOWNLOAD_SCORECARD],
      },
      {
        label: "About Programme",
        name: "about_programme",
        to: "/about",
        id: "-1",
        permission: [Permissions.ABOUT_PROGRAMME],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
  {
    defaultImage: "images/sidebar-icons/customer_list_icon.png",
    selectedImage: "images/sidebar-icons/customer_icon.png",
    label: "Customer List",
    link_note: "Customers, Trade and Visit history",
    id: "2",
    name: "customer_management",
    permission: [Permissions.CUSTOMER_MANAGEMENT],
    content: [
      {
        label: "Add Customer Visit",
        to: "/",
        name: "customer_management",
        id: "-1",
        permission: [Permissions.ADD_CUSTOMER_VISIT],
      },
      {
        label: "Customer List",
        to: "/customer-management",
        name: "customer_list",
        id: "-1",
        permission: [Permissions.CUSTOMER_LIST],
      },
      {
        label: "Manage Group",
        to: "/manage-group",
        name: "manage_group",
        id: "-1",
        permission: [Permissions.MANAGE_GROUP],
      },
      {
        label: "Block List",
        name: "customer_management",
        to: "/block-list",
        id: "-1",
        permission: [Permissions.BLOCK_LIST],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/campaigns_icon.png",
    selectedImage: "images/sidebar-icons/campaigns_icn.png",
    label: "Campaigns",
    link_note: "Create, edit, delete and schedule campaigns",
    id: "3",
    name: "campaign",
    permission: [Permissions.CAMPAIGN],
    content: [
      {
        label: "Create Campaign",
        to: "/campaign/create",
        name: "create_campaign",
        id: "-1",
        permission: [Permissions.CREATE_CAMPAIGN],
      },
      {
        label: "Buy SMS",
        to: "/campaign/create",
        name: "buy_sms",
        id: "-1",
        permission: [Permissions.BUY_SMS],
      },
      {
        label: "Schedule Campaign",
        to: "/campaign/schedule",
        name: "schedule_camapaign",
        id: "-1",
        permission: [Permissions.SCHEDULE_CAMPAIGN],
      },
      {
        label: "Campaign History",
        to: "/campaign/history",
        name: "campaign_history",
        id: "-1",
        permission: [Permissions.CAMPAIGN_HISTORY],
      },
      {
        label: "SMS Report",
        to: "/sms-report",
        name: "sms_report",
        id: "-1",
        permission: [Permissions.SMS_REPORT],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/redeem_coupon.svg",
    selectedImage: "images/sidebar-icons/redeem_coupon_selected.svg",
    label: "Redeem Marketing Coupon",
    link_note: "Redeem Discount Coupon,View Report",
    id: "4",
    name: "redeem_marketing_coupon",
    permission: [Permissions.REDEEM_MARKETING_COUPON],
    content: [
      {
        label: "Redeem Discount Coupon",
        to: null,
        name: "redeem_marketing_coupon",
        id: "-1",
        permission: [Permissions.REDEEM_MARKETING_DISCOUNT_COUPON],
      },
      {
        label: "View Report",
        to: "/redeem-coupon-report",
        name: "redeem_coupon_report",
        id: "-1",
        permission: [Permissions.MARKETING_COUPON_REDEEM_REPORT],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Catalogue",
    link_note: "Points Redemption",
    id: "5",
    name: "catalogue",
    permission: [Permissions.CATALOGUE],
    content: [
      {
        label: "Redemption Catalogue",
        to: "/redemption-catalog",
        name: "redemption_catalogue",
        id: "-1",
        permission: [Permissions.REDEMPTION_CATALOGUE],
      },
      {
        label: "Merchandise Catalogue",
        to: "/merchandise-wallet",
        name: "merchandise_wallet",
        id: "-1",
        permission: [Permissions.MERCHANDISE_CATALOGUE],
      },
    ],
  },
  {
    to: "/invite-dealer",
    defaultImage: "images/sidebar-icons/invite_icon.png",
    selectedImage: "images/sidebar-icons/invite_icon_copy.png",
    label: "Invite Staff / Branch / Sub Dealer",
    link_note: "Warranty, Add Customer",
    name: "invite_branch_sub_dealer",
    id: "6",
    permission: [Permissions.INVITE_BRANCH_SUB_DEALER],
  },
  {
    to: "/download_poster",
    name: "download_poster",
    defaultImage: "images/sidebar-icons/Poster.png",
    selectedImage: "images/sidebar-icons/Poster_selected.png",
    label: "Download Poster",
    link_note: "Download Poster",
    id: "7",
    permission: [Permissions.DOWNLOAD_POSTER],
  },
  {
    defaultImage: "images/sidebar-icons/setting_icn_inactivr.png",
    selectedImage: "images/sidebar-icons/setting_icon.png",
    label: "Settings",
    link_note: "Notification and Email Preference",
    id: "8",
    name: "settings",
    permission: [Permissions.SETTINGS],
    content: [
      {
        label: "Your Preferences",
        to: "/notification-preference",
        name: "notification_preference",
        id: "-1",
        permission: [Permissions.NOTIFICATION_PREFERENCE],
      },
      {
        label: "Email Preference",
        to: "/email-preference",
        name: "email_preference",
        id: "-1",
        permission: [Permissions.EMAIL_PREFERENCE],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "My Account",
    link_note: "Profile And Orders",
    id: "9",
    name: "my_account",
    permission: [Permissions.ACCOUNT],
    content: [
      {
        label: "Profile",
        to: "/profile",
        name: "profile",
        id: "-1",
        permission: [Permissions.PROFILE],
      },
      {
        label: "Order History",
        to: "/order-history",
        name: "order_history",
        id: "-1",
        permission: [Permissions.ORDER_HISTORY],
      },
    ],
  },
  {
    to: "/contact-us",
    name: "contact_us",
    defaultImage: "images/sidebar-icons/contact_icon.png",
    selectedImage: "images/sidebar-icons/contact_icon_copy.png",
    label: "Contact Us",
    link_note: "Office Address",
    id: "10",
    permission: [Permissions.CONTACT_US],
  },
];

const subDealerAndBranchMenus = [
  {
    to: "/warranty-score-card",
    defaultImage: "images/sidebar-icons/secondary-report.svg",
    selectedImage: "images/sidebar-icons/secondary-report-selected.svg",
    label: "Warranty Report",
    name: "warranty_report",
    id: "0",
    permission: [Permissions.WARRANTY_REPORT],
  },
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "My Account",
    link_note: "Profile",
    id: "1",
    name: "account",
    permission: [Permissions.ACCOUNT],
    content: [
      {
        label: "Profile",
        to: "/profile",
        name: "profile",
        id: "-1",
        permission: [Permissions.PROFILE],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const distributorMenus = [
  {
    to: "/warranty-score-card",
    defaultImage: "images/sidebar-icons/secondary-report.svg",
    selectedImage: "images/sidebar-icons/secondary-report-selected.svg",
    label: "Warranty Report",
    name: "warranty_report",
    id: "0",
    permission: [Permissions.WARRANTY_REPORT],
  },
  {
    to: "/invite-dealer",
    defaultImage: "images/sidebar-icons/invite_icon.png",
    selectedImage: "images/sidebar-icons/invite_icon_copy.png",
    label: "Invite Staff / Branch / Sub Dealer",
    link_note: "Warranty, Add Customer",
    name: "invite_branch_sub_dealer",
    id: "1",
    permission: [Permissions.INVITE_BRANCH_SUB_DEALER],
  },
  {
    defaultImage: "images/sidebar-icons/user.png",
    selectedImage: "images/sidebar-icons/user_selected.png",
    label: "My Account",
    link_note: "Profile",
    id: "2",
    name: "account",
    permission: [Permissions.ACCOUNT],
    content: [
      {
        label: "Profile",
        to: "/profile",
        name: "profile",
        id: "-1",
        permission: [Permissions.PROFILE],
      },
    ],
  },
  {
    defaultImage: "images/sidebar-icons/catalogue_icon.png",
    selectedImage: "images/sidebar-icons/catalogue_icn.png",
    label: "Catalogue",
    link_note: "Points Redemption",
    id: "3",
    name: "catalogue",
    permission: [Permissions.CATALOGUE],
    content: [
      {
        label: "Redemption Catalogue",
        to: "/redemption-catalog",
        name: "redemption_catalogue",
        id: "-1",
        permission: [Permissions.REDEMPTION_CATALOGUE],
      },
      {
        label: "Merchandise Catalogue",
        to: "/merchandise-wallet",
        name: "merchandise_wallet",
        id: "-1",
        permission: [Permissions.MERCHANDISE_CATALOGUE],
      },
    ],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "4",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const adminMenus = [
  {
    to: "/set-notification",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Set Notification",
    name: "set-notification",
    id: "0",
    permission: [Permissions.SET_NOTIFICATION],
  },
  {
    to: "/sales-data-upload",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Sales Data Upload",
    name: "sales_data_upload",
    id: "1",
    permission: [Permissions.SALES_DATA_UPLOAD],
  },
  {
    to: "/migrate-monthly-data",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Migrate Monthly Points",
    name: "migrate_monthly_points",
    id: "2",
    permission: [Permissions.MIGRATE_MONTHLY_POINTS],
  },
  {
    to: "/migrate-quarterly-data",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Migrate Quarterly Points",
    name: "migrate_quarterly_points",
    id: "3",
    permission: [Permissions.MIGRATE_QUARTERLY_POINTS],
  },
  {
    to: "/migrate-yearly-data",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    title: "Migrate Yearly Points",
    isActive: false,
    label: "Migrate Yearly Points",
    id: "4",
    key: "Migrate Yearly Points",
    name: "migrate_yearly_points",
    permission: [Permissions.MIGRATE_YEARLY_POINTS],
  },
  {
    to: "/gift-item-list",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    name: "gift_item",
    label: "Gift Item List",
    id: "5",
    permission: [Permissions.GIFT_ITEM],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "6",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const marketingMenus = [
  {
    to: "/set-notification",
    defaultImage: "images/sidebar-icons/dashboard.svg",
    selectedImage: "images/sidebar-icons/dashboard-selected.svg",
    label: "Set Notification",
    name: "set-notification",
    id: "0",
    permission: [Permissions.SET_NOTIFICATION],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];

const supportMenus = [
  {
    to: "/merchandise-orders",
    defaultImage: "images/sidebar-icons/detail.svg",
    selectedImage: "images/sidebar-icons/detail.svg",
    label: "Merchandise Order",
    name: "merchandise_order",
    id: "0",
    permission: [Permissions.MERCHANDISE_ORDER],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];
const vendorMenus = [
  {
    to: "/retail-marketing-system",
    defaultImage: "images/sidebar-icons/RetailMgtSystemm.png",
    selectedImage: "images/sidebar-icons/RetailMgtSystemSelected.svg",
    link_note: "Add Recce, View Recce Status",
    label: "Retail Marketing System",
    name: "RETAIL_BRANDING_LIST",
    id: "0",
    permission: [Permissions.RETAIL_BRANDING_LIST],
    imageIconCustomCss: {
      height: "20px",
      width: "25px",
    },
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "1",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];
const brandStoreMenu = [
  {
    label: "Proposal Dashboard",
    defaultImage: "images/sidebar-icons/proposal_dashboard_ina.png",
    selectedImage: "images/sidebar-icons/proposal_dashboard.png",
    link_note: "New Brand Store Registration",
    to: "/proposal-dashboard",
    name: "proposal_dashboard",
    id: "1",
    permission: [Permissions.PROPOSAL_DASHBOARD],
  },
  {
    label: "Customer Interaction Program",
    defaultImage: "images/sidebar-icons/customer_interaction_ina.png",
    selectedImage: "images/sidebar-icons/customer_interaction.png",
    link_note: "CIP Registration and Summary",
    name: "cip",
    to: "/cip-summary",
    id: "2",
    permission: [Permissions.CUSTOMER_INTERACTION]
  },
];
let selectedIcon = null;

class CustomLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetail: Storage.getUserDetail(),
      menu: [],
      clickDownloadScorecard: null,
      clickRedeemCoupon: null,
      hideNav: Storage.getHideNavBar(),
    };

    // if (this.state.userDetail.type === Roles.HEAD_OFFICE_ROLE) {
    //   this.state.menu = headOfficeMenu;
    // } else if (this.state.userDetail.type === Roles.AREA_MANAGER_ROLE) {
    //   this.state.menu = employeeSideMenus;
    // } else if (this.state.userDetail.type === Roles.SALES_OFFICER_ROLE) {
    //   this.state.menu = salesOfficerSideMenus;
    // } else if (
    //   this.state.userDetail.type === Roles.REGION_MANAGER_ROLE ||
    //   this.state.userDetail.type === Roles.ZONE_MANAGER_ROLE
    // ) {
    //   this.state.menu = allOtherRoles;
    // } else if (this.state.userDetail.type === Roles.PROJECT_MANAGER_ROLE) {
    //   this.state.menu = pmMenu;
    // } else if (this.state.userDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
    //   this.state.menu = systemArchitectMenu;
    // } else if (this.state.userDetail.type.toLowerCase() === Roles.SUPER_ADMIN_ROLE) {
    //   this.state.menu = adminMenus;
    // } else if (this.state.userDetail.type.toLowerCase() === Roles.MARKETING_ROLE) {
    //   this.state.menu = marketingMenus;
    // } else if (this.state.userDetail.type.toLowerCase() === Roles.SUPPORT_ROLE) {
    //   this.state.menu = supportMenus;
    // } else if (
    //   this.state.userDetail.type.toLowerCase() === Roles.SUB_DEALER_ROLE ||
    //   this.state.userDetail.type.toLowerCase() === Roles.BRANCH_ROLE
    // ) {
    //   this.state.menu = subDealerAndBranchMenus;
    // } else {
    // }

    if (menusToShow && Array.isArray) {
      menusToShow.forEach((element, i) => {
        element.id = i;
        element.id = element.id.toString();
      });
    }
    this.state.menu = menusToShow;

    this.onClick = this.onClick.bind(this);
  }

  componentWillMount() {
    this.setSelectedIcon();
  }

  setSelectedIcon = () => {
    const url = window.location.href.split("#")[1];
    localStorage.setItem("activeLink", url);
    if (
      url === "/" ||
      url === "/dashboard" ||
      url === "/point-dashboard" ||
      url === "/offtake-score-card-report" ||
      url === "/warranty-score-card-report" ||
      url === "/about" ||
      url === "/merchandise-wallet" ||
      url === "/redemption-catalog" ||
      url === "/campaign" ||
      url === "/campaign/schedule" ||
      url === "/manage-group" ||
      url === "/customer-management" ||
      url === "/campaign/create" ||
      url === "/campaign/schedule" ||
      url === "/campaign/history" ||
      url === "/customer-management/block-list" ||
      url === "/dealer-dashboard"
    ) {
      selectedIcon = "-1";
    }
  };

  componentWillReceiveProps() {
    this.setSelectedIcon();
  }

  componentDidMount() {
    active_id = localStorage.getItem("active_id");
  }

  onClick(e) {
    if (this.props.hasSubMenu) this.props.toggleSubMenu(e);
    else {
      if (
        !this.props.hasSubMenu &&
        !this.props.to &&
        this.props.label === "Redeem Discount Coupon"
      ) {
        this.state.clickRedeemCoupon = true;
      } else {
        this.state.clickRedeemCoupon = false;
      }

      if (
        !this.props.hasSubMenu &&
        !this.props.to &&
        this.props.label === "Download Score Card"
      ) {
        this.state.clickDownloadScorecard = true;
      } else {
        this.state.clickDownloadScorecard = false;
      }

      selectedIcon = this.props.id;
      this.props.activateMe({
        newLocation: this.props.to,
        selectedMenuLabel: this.props.label,
        clickDownloadScorecard: this.state.clickDownloadScorecard,
        clickRedeemCoupon: this.state.clickRedeemCoupon,
        id: this.props.id,
      });
    }
  }

  render() {
    const { menu, userDetail } = this.state;

    return (
      <>
        {/* {this.props.hasSubMenu && ( */}
        {menu[parseInt(this.props.id)] && (
          <div>
            {localStorage.getItem("activeLink") === this.props.to ? (
              <img
                src={
                  menu[parseInt(this.props.id)] &&
                  menu[parseInt(this.props.id)].selectedImage
                }
                alt=""
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  zIndex: "1",
                  width: "20px",
                  // height: "20px",
                  height:
                    menu[this.props.id].selectedImage ===
                      "images/location_icon_yellow.png"
                      ? "25px"
                      : "20px",
                  ...menu[parseInt(this.props.id)].imageIconCustomCss,
                }}
              />
            ) : (
              <span>
                {selectedIcon === "-1" &&
                  parseInt(this.props.id) === parseInt(active_id) ? (
                  <>
                    <img
                      src={
                        menu[this.props.id] && menu[this.props.id].selectedImage
                      }
                      alt=""
                      style={{
                        marginTop: "10px",
                        position: "absolute",
                        zIndex: "1",
                        width: "20px",
                        height:
                          menu[this.props.id].selectedImage ===
                            "images/location_icon_yellow.png"
                            ? "25px"
                            : "20px",
                        ...menu[parseInt(this.props.id)].imageIconCustomCss,
                      }}
                    />
                  </>
                ) : (
                  <img
                    src={
                      menu[parseInt(this.props.id)] &&
                      menu[parseInt(this.props.id)].defaultImage
                    }
                    alt=""
                    style={{
                      marginTop: "10px",
                      position: "absolute",
                      zIndex: "1",
                      width: "20px",
                      // height: "25px",
                      height:
                        menu[this.props.id].defaultImage ===
                          "images/location_con_grey.png"
                          ? "25px"
                          : "20px",
                      ...menu[parseInt(this.props.id)].imageIconCustomCss,
                    }}
                  />
                )}
              </span>
            )}
          </div>
        )}

        {!this.props.hasSubMenu && this.props.id !== "-1" && (
          <div className="margin-top-22" />
        )}
        <span
          className="metismenu-link d-flex-column"
          style={{ display: "flex", flexDirection: "column" }}
          onClick={this.onClick}
        >
          <span
            className={
              parseInt(this.props.id) > -1
                ? parseInt(localStorage.getItem("active_id")) ==
                  parseInt(this.props.id)
                  ? "title active-color"
                  : "title"
                : "sub-menu-title"
            }
            style={{
              marginTop:
                menu[parseInt(this.props.id)] &&
                  menu[parseInt(this.props.id)].link_note &&
                  menu[parseInt(this.props.id)].selectedImage
                  ? "0px"
                  : "13px",
            }}
          >
            {this.props.children}
          </span>

          {menu[parseInt(this.props.id)] && (
            <>
              <span className="sub-menu">
                {menu[parseInt(this.props.id)] &&
                  menu[parseInt(this.props.id)].link_note
                  ? menu[parseInt(this.props.id)].link_note
                  : ""}
              </span>
            </>
          )}

          {!this.props.hasSubMenu && (
            <i className="metismenu-state-icon pe-7s-angle-right fa fa-caret-left" />
          )}
        </span>
        {this.props.hasSubMenu && <div className="margin-top-22" />}
        {!this.props.hasSubMenu && this.props.id !== "-1" && (
          <div className="margin-top-22" />
        )}
      </>
    );
  }
}

class AppSidebar extends Component {
  isMobile = false;

  userDetail = {};

  isDisplayLogOut = false;

  constructor(props) {
    super(props);
    this.state = {
      navBarToHide: Storage.getHideNavBar() ? Storage.getHideNavBar() : [],
      isLogoutModalOpen: false,
      activeNav: 100,
      isLoading: false,
      redirectToLoginPage: false,
      redirectTo: null,
      downloadScorecardStyle: {
        fontSize: "16px",
      },
      menusToShow: [],
      userDetail: null,
      userRoleDetail: Storage.getUserDetail(),
    };
    this.userDetail = Storage.getUserDetail();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (localStorage.getItem("redirectDetails")) {
      const redirectDetails = JSON.parse(
        localStorage.getItem("redirectDetails")
      );
      if (redirectDetails.isRedeemCouponOpen) {
        this.openRedeemCouponModel();
      }
      localStorage.removeItem("redirectDetails");
    }
  }

  getActiveLink(tab) {
    if (this.state.activeNav !== tab) {
      this.setState({
        activeNav: tab,
      });
    }
  }

  componentDidMount() {
    this.checkUserAgent();
    this.navBarToHide();
    this.setMenus();
  }

  componentWillReceiveProps(props) {
    if (props.updateSideMenuValue) {
      this.setMenus();
      this.props.updateSideMenu(false);
    }
  }

  navBarToHide = () => {
    DashboardService.navBarToHide()
      .then((data) => {
        this.setState({
          navBarToHide: data.data.data.menu,
        });
        Storage.setHideNavBar(data.data.data.menu);
      })
      .catch((e) => {
        console.error("e", e);
      });
  };

  componentWillMount() {
    this.checkUserAgent();
  }

  toggleMobileSidebar = () => {
    const { enableMobileMenu, setEnableMobileMenu } = this.props;
    this.isDisplayLogOut = enableMobileMenu;
    setEnableMobileMenu(!enableMobileMenu);
  };

  openLogoutModal = (e) => {
    if (e) {
      e.preventDefault();
    }
    // reason = null;
    modalButtons = [
      {
        color: "#fdbb1f",
        title: "Cancel",
        style: { backgroundColor: "#fdbb1f" },
        onClick: this.closeLogoutModal,
      },
      {
        color: "#e0e2e4",
        title: "Logout",
        style: { backgroundColor: "#e0e2e4", padding: "0px" },
        onClick: this.logout,
        isActive: false,
        key: "Logout",
      },
      // {
      //   color: '#e0e2e4',
      //   title: 'Logout All',
      //   style: { backgroundColor: '#e0e2e4' },
      //   onClick: this.logoutFromAllDevice,
      // },
    ];
    // child = <Input placeholder="Reason" onChange={this.changeReasonToDelete} />;
    this.setState({ isLogoutModalOpen: true });
  };

  closeLogoutModal = () => {
    this.setState({ isLogoutModalOpen: false });
  };

  openScorcardModel = () => {
    this.setState({ isScorCardOpen: true });
  };

  closeScorcardModel = () => {
    this.setState({ isScorCardOpen: false });
  };

  openRedeemCouponModel = () => {
    this.setState({ isRedeemCouponOpen: true });
  };

  closeRedeemCouponModel = () => {
    this.setState({ isRedeemCouponOpen: false });
  };

  logout = () => {
    AuthService.logout();
    this.props.setUserDetail(null);

    // Storage.removeKeepMeLoggedInCookie();
    // Storage.removeTokenDetail();
    // Storage.removeUserDetail();
    // // this.closeLogoutModal();

    this.props.history.replace("/login");
  };

  logoutFromAllDevice = () => {
    AuthService.logoutFromAllDevice()
      .then((data) => {
        this.logout();
      })
      .catch((e) => {
        this.logout();
      });
  };

  checkUserAgent() {
    const ua = navigator.userAgent;
    this.isMobile = false;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.isMobile = true;
      return true;
    }
    if (/Chrome/i.test(ua)) {
      return false;
    }
    return false;
  }

  closeSidebar = () => {
    this.toggleMobileSidebar();

    // this.setState({
    // });
  };

  checkActive = (match, location) => {
    if (!match) {
      return false;
    }
    return true;
  };

  downloadScorecard = () => {
    this.setState({
      isLoading: true,
    });
    DashboardService.downloadScoreCard()
      .then((data) => {
        this.setState({
          isLoading: false,
        });
        const { location } = data.data.data;
        downlodFile(location);
      })
      .catch((e) => {
        this.setState({
          isLoading: false,
        });
        console.error("e", e);
      });
  };

  downloadScorecardMouseEvent = (isHover) => {
    let style = {
      fontSize: "16px",
    };
    if (isHover) {
      style = {
        fontSize: "16px",
        cursor: "pointer",
        textDecoration: "underline",
        color: "#0056b3",
      };
    }
    this.setState({ downloadScorecardStyle: style });
  };

  onMenuSelected = (data) => {
    // that.refmenu.changeActiveLinkId(1)
    // this.setState({
    //   activeLinkId: "0"
    // })
    // that. refs.menu.changeActiveLinkTo('dashboard');
    let findIndexChild = -1;

    menusToShow &&
      menusToShow.forEach((element) => {
        if (element.content && element.content.length > 0) {
          findIndexChild = element.content.findIndex(
            (x) =>
              x.to === data.newLocation && x.label === data.selectedMenuLabel
          );
        } else {
          findIndexChild = -1;
        }
        if (findIndexChild > -1) {
          active_id = element.id;
          localStorage.setItem("active_id", active_id);
        }
      });

    if (parseInt(data.id) > -1) {
      localStorage.setItem("active_id", data.id);
    }
    selectedIcon = data.id;
    if (
      data.selectedMenuLabel &&
      data.selectedMenuLabel === "Add Customer Visit"
    ) {
      this.props.setAddCustomerRedirect({ redirectToAddCustomer: true });
    } else if (data.selectedMenuLabel && data.selectedMenuLabel === "Buy SMS") {
      this.props.setRedirect({ buySMS: true });
    } else if (
      data.selectedMenuLabel &&
      data.selectedMenuLabel === "Generate Coupon"
    ) {
      this.props.setRedirect({ generateCoupon: true });
    } else if (data.selectedMenuLabel && data.selectedMenuLabel === "Logout") {
      this.openLogoutModal();
    } else if (data.selectedMenuLabel && data.selectedMenuLabel === "Report") {
      this.props.setRedirect({ generateCoupon: false });
    }
    if (data.newLocation === this.props.location.pathname) {
      return;
    }

    if (data.clickDownloadScorecard) {
      this.openScorcardModel();
    }

    if (data.clickRedeemCoupon) {
      this.openRedeemCouponModel();
    }

    this.props.history.replace(data.newLocation);
    if (data.newLocation) {
      localStorage.setItem("activeLink", data.newLocation);
      this.state.redirectTo = data.newLocation;
    }
  };

  setMenus = () => {
    let {
      menusToShow,
      userRoleDetail,
      // navBarToHide
    } = this.state;
    let permissions = [];
    if (userRoleDetail && userRoleDetail.permissions) {
      permissions = userRoleDetail.permissions;
    }

    // if (
    //   this.userDetail.type === Roles.DEALER_ROLE &&
    //   process.env.REACT_APP_NODE_ENV === "production"
    // ) {
    //   const index = advantageSideMenus.findIndex((x) => x.key === "Campaign");
    //   if (index > -1) {
    //     advantageSideMenus.splice(index, 1);
    //   }

    //   const indexCustomer = advantageSideMenus.findIndex(
    //     (x) => x.key === "customer-management"
    //   );
    //   if (index > -1) {
    //     advantageSideMenus.splice(indexCustomer, 1);
    //   }
    // }

    // if (
    //   userRoleDetail.dealer_type === "steel wheels" ||
    //   userRoleDetail.dealer_type === "xpress wheels"
    // ) {
    //   const index =
    //     advantageSideMenus &&
    //     advantageSideMenus.findIndex((x) => x.key === "About Programme");
    //   if (index > -1) {
    //     advantageSideMenus.splice(index, 0, {
    //       to: "/merchandise-wallet",
    //       defaultImage: "images/sidebar-icons/MerchandiseWallet.svg",
    //       selectedImage: "images/sidebar-icons/MerchandiseWalletSelected.svg",
    //       title: "Merchandise Wallet",
    //       isActive: false,
    //       key: "merchandise-wallet",
    //       name: "merchandise_wallet",
    //     });
    //   }
    // }

    if (
      userRoleDetail.type.toLowerCase() === Roles.EMPLOYEE_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.HEAD_OFFICE_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.SALES_OFFICER_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.AGENCY_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.AREA_MANAGER_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.REGION_MANAGER_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.ZONE_MANAGER_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.SUB_DEALER_ROLE ||
      userRoleDetail.customer_class === "hy" ||
      userRoleDetail.type.toLowerCase() === Roles.DISTRIBUTOR_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.BRANCH_ROLE ||
      userRoleDetail.type.toLowerCase() === Roles.ZTM_ROLE
    ) {
      if (
        [
          Roles.ZONE_MANAGER_ROLE,
          Roles.REGION_MANAGER_ROLE,
          Roles.ZTM_ROLE,
        ].includes(userRoleDetail.type.toLowerCase())
      ) {
        menusToShow = allOtherRoles;
      } else if (
        [Roles.SALES_OFFICER_ROLE].includes(userRoleDetail.type.toLowerCase())
      ) {
        menusToShow = salesOfficerSideMenus;
      } else if (
        [Roles.HEAD_OFFICE_ROLE].includes(userRoleDetail.type.toLowerCase())
      ) {
        menusToShow = headOfficeMenu;
      } else if (
        [Roles.SUB_DEALER_ROLE, Roles.BRANCH_ROLE].includes(
          userRoleDetail.type.toLowerCase()
        )
      ) {
        menusToShow = subDealerAndBranchMenus;
      } else if (
        (userRoleDetail.customer_class &&
          [Roles.DISTRIBUTOR_ROLE].includes(
            userRoleDetail.type.toLowerCase()
          )) ||
        (userRoleDetail.customer_class &&
          ["na"].includes(userRoleDetail.customer_class.toLowerCase()))
      ) {
        menusToShow = distributorMenus;
      } else if (
        userRoleDetail.customer_class &&
        ["hy"].includes(userRoleDetail.customer_class.toLowerCase())
      ) {
        menusToShow = hyMenu;
      } else if (
        userRoleDetail.access_level && userRoleDetail.access_level.toLowerCase() === "area" ||
        userRoleDetail.access_level && userRoleDetail.access_level.toLowerCase() === "all"
      ) {
        menusToShow = employeeSideMenus;
      } else if (userRoleDetail.type.toLowerCase() === Roles.ARCHITECT_ROLE) {
        // systemArchitectMenu.push({
        //   to: "/proposal-assign",
        //   defaultImage: "images/sidebar-icons/icon-Architect.svg",
        //   selectedImage: "images/sidebar-icons/icon-Architect-selected.svg",
        //   title: "Proposal Assign",
        //   isChangeWidth: true,
        //   isActive: false,
        //   key: "Proposal Assign",
        // });
        menusToShow = systemArchitectMenu;
      } else {
        menusToShow = systemArchitectMenu;
      }
      // if (userRoleDetail.access_level === 'all' || userRoleDetail.access_level === 'area') {
      //   menusToShow = systemArchitectMenu;
      // }else{
      //   menusToShow = employeeSideMenus;
      // }
    } else if (userRoleDetail.type.toLowerCase() === Roles.MARKETING_ROLE) {
      menusToShow = marketingMenus;
    } else if (userRoleDetail.type.toLowerCase() === Roles.SUPER_ADMIN_ROLE) {
      menusToShow = adminMenus;
    } else if (userRoleDetail.type.toLowerCase() === Roles.SUPPORT_ROLE) {
      menusToShow = supportMenus;
    } else if (
      userRoleDetail.type.toLowerCase() === Roles.PROJECT_MANAGER_ROLE
    ) {
      // if (
      //   ExceptionalPointUploadUsers.includes(userRoleDetail.sap_id) === true
      // ) {
      //   let obj = pmMenu.find((o) => o.name === "exceptional_points_upload");
      //   if (parseInt(obj && obj.id) > -1) {
      //     pmMenu.splice(obj.id, 1);
      //   }
      //   pmMenu.push({
      //     to: "/exceptional-points-upload",
      //     defaultImage: "images/sidebar-icons/dashboard.svg",
      //     selectedImage: "images/sidebar-icons/dashboard-selected.svg",
      //     label: "Exceptional Points Upload",
      //     link_note: "Scheme Or Approval Points",
      //     id: "3",
      //     name: "exceptional_points_upload",
      //   });
      // }

      // if (
      //   ExceptionalPointUploadUsers.includes(userRoleDetail.sap_id) === false
      // ) {
      //   let bannerIndex = pmMenu.findIndex(p => p.name === 'advertising_banners')
      //   if (bannerIndex > -1) {
      //     pmMenu.splice(bannerIndex, 1);
      //   }
      // }
      menusToShow = pmMenu;
    } else if (userRoleDetail.type.toLowerCase() === Roles.ACCOUNTANT_ROLE) {
      menusToShow = accountantMenu;
    } else if (userRoleDetail.type.toLowerCase() === Roles.VENDOR_ROLE) {
      menusToShow = vendorMenus;
    } else if (userRoleDetail.type.toLowerCase() === Roles.FINANCE_ROLE) {
      menusToShow = financeMenu;
    } else if (
      [Roles.BRAND_STORE_ROLE].includes(userRoleDetail.type.toLowerCase())
    ) {
      menusToShow = brandStoreMenu;
    } else {
      menusToShow = advantageSideMenus;
    }

    // let advantageSideMenus = Object.assign(advantageMenus);
    const currentPath = this.props.location.pathname;
    const locationIndex =
      menusToShow && menusToShow.findIndex((o) => o.to === currentPath);
    if (locationIndex > -1) {
      menusToShow[locationIndex].isActive = true;
    }

    // hide nav bar
    // if (navBarToHide && navBarToHide.length > 0) {
    // let result1 = menusToShow.filter((element, index) => {
    //   if (navBarToHide) {
    //     return navBarToHide.indexOf(element.name) === -1;
    //   }
    // });
    // let result2 = result1.filter((element, index) => {
    //   if (navBarToHide && element.content) {
    //     return element.content.filter((x, i) => {
    //       return navBarToHide.indexOf(x.name) === -1
    //     })
    //   } else {
    //     return true;
    //   }
    // });
    // menusToShow = result2;
    if (
      this.state.userRoleDetail &&
      this.state.userRoleDetail.permissions &&
      this.state.userRoleDetail.permissions.includes(
        Permissions.DEALER_NEW_DASHBOARD
      )
    ) {
      menusToShow.forEach((element) => {
        if (element.name && element.name === "offtake_points") {
          if (element.content) {
            element.content.forEach((elemen2) => {
              if (elemen2.name === "points_dashboard") {
                elemen2.to = "/?is_from_v2_dashbaord=true";
              }
            });
          }
        }

        if (element.name && element.name === "customer_management") {
          if (element.content) {
            element.content.forEach((elemen2) => {
              if (elemen2.label === "Add Customer Visit") {
                elemen2.to = "/dealer-dashboard";
              }
            });
          }
        }
      });
    }
    // if (userRoleDetail && userRoleDetail.type &&
    //   userRoleDetail.type === Roles.DEALER_ROLE) {

    //   let index_campaign = this.state.navBarToHide.findIndex(x => x && x.toLowerCase() === 'campaign');
    //   if (index_campaign > -1) {
    //     let index_my_account = menusToShow.findIndex(x => x && x.name.toLowerCase() === 'my_account');
    //     if (index_my_account > -1) {
    //       let index_order_history = menusToShow[index_my_account].content.findIndex(x => x && x.name.toLowerCase() === 'order_history');
    //       menusToShow[index_my_account].link_note = 'Profile';
    //       if (index_order_history > -1) {
    //         menusToShow[index_my_account].content.splice(index_order_history, 1);
    //       }
    //     }
    //   }
    // }
    const finalMenu = [];

    for (let i = 0; i < menusToShow.length; i++) {
      if (
        menusToShow[i].link_note &&
        menusToShow[i].link_note.indexOf(", Trade Scheme") > -1 &&
        permissions.includes(Permissions.TRADE_SCHEME) === false
      ) {
        const fromIndex = menusToShow[i].link_note.indexOf(", Trade Scheme");
        const lastIndex = ", Trade Scheme".length + fromIndex;
        if (fromIndex > -1 && lastIndex > -1) {
          const one = String(menusToShow[i].link_note).slice(0, fromIndex);
          const two = String(menusToShow[i].link_note).slice(
            lastIndex,
            menusToShow[i].link_note.length
          );
          menusToShow[i].link_note = one + two;
        }
      }
      if (
        menusToShow[i].link_note &&
        menusToShow[i].link_note.indexOf(", Add Customer") > -1 &&
        permissions.includes(Permissions.ADD_CUSTOMER_VISIT) === false
      ) {
        const fromIndex = menusToShow[i].link_note.indexOf(", Add Customer");
        const lastIndex = ", Trade Scheme".length + fromIndex;
        if (fromIndex > -1 && lastIndex > -1) {
          const one = String(menusToShow[i].link_note).slice(0, fromIndex);
          const two = String(menusToShow[i].link_note).slice(
            lastIndex,
            menusToShow[i].link_note.length
          );
          menusToShow[i].link_note = one + two;
        }
      }
      if (
        menusToShow[i].link_note &&
        menusToShow[i].link_note.indexOf("And Orders") > -1 &&
        permissions.includes(Permissions.ORDER_HISTORY) === false
      ) {
        const fromIndex = menusToShow[i].link_note.indexOf("And Orders");
        const lastIndex = "And Orders".length + fromIndex;
        if (fromIndex > -1 && lastIndex > -1) {
          const one = String(menusToShow[i].link_note).slice(0, fromIndex);
          const two = String(menusToShow[i].link_note).slice(
            lastIndex + 1,
            menusToShow[i].link_note.length
          );
          menusToShow[i].link_note = one + two;
        }
      }

      if (
        menusToShow[i].link_note &&
        menusToShow[i].link_note.indexOf(", Warranty") > -1 &&
        permissions.includes(Permissions.WARRANTY_REPORT_EMPLOYEE) === false &&
        permissions.includes(Permissions.WARRANTY_REPORT) === false
      ) {
        const fromIndex = menusToShow[i].link_note.indexOf(", Warranty");
        const lastIndex = ", Warranty".length + fromIndex;
        if (fromIndex > -1 && lastIndex > -1) {
          const one = String(menusToShow[i].link_note).slice(0, fromIndex);
          const two = String(menusToShow[i].link_note).slice(
            lastIndex + 1,
            menusToShow[i].link_note.length
          );
          menusToShow[i].link_note = `${one}, ${two}`;
        }
      }

      const currentMenu = menusToShow[i];
      let addMenu = false;
      if (currentMenu.permission && currentMenu.permission.length > 0) {
        for (let j = 0; j < currentMenu.permission.length; j++) {
          const currentPermission = currentMenu.permission[j];
          if (permissions.includes(currentPermission)) {
            addMenu = true;
            break;
          }
        }
      }
      if (addMenu === false) {
        continue;
      }
      const mainMenuObj = JSON.parse(JSON.stringify(currentMenu));
      const childArray = [];
      if (mainMenuObj.content && mainMenuObj.content.length > 0) {
        for (let j = 0; j < mainMenuObj.content.length; j++) {
          // let currentChildMenu =JSON.parse(JSON.stringify(mainMenuObj.content[j]));
          let addChildMenu = false;
          const currentChildMenu = mainMenuObj.content[j];
          if (
            currentChildMenu.permission &&
            currentChildMenu.permission.length > 0
          ) {
            for (let k = 0; k < currentChildMenu.permission.length; k++) {
              const currentPermission = currentChildMenu.permission[k];
              if (permissions.includes(currentPermission)) {
                addChildMenu = true;
                break;
              }
            }
          }
          if (addChildMenu === false) {
            continue;
          }
          const childMenuObj = currentChildMenu;
          childArray.push(childMenuObj);
        }
      }
      mainMenuObj.content = childArray;
      finalMenu.push(mainMenuObj);
    }
    menusToShow = finalMenu;

    // }
    if (Array.isArray(menusToShow)) {
      if (menusToShow.findIndex((x) => x.label === "Logout") <= -1) {
        menusToShow.push({
          defaultImage: "images/logout_icon.png",
          selectedImage: "images/logout_icon.png",
          label: "Logout",
          link_note: "",
          id: "-1",
          name: "logout",
        });
      }
    }
    this.setState({
      menusToShow,
    });
    menusToShow = menusToShow;
  };

  onMouseOutMenu = () => { };

  render() {
    const {
      backgroundColor = true,
      enableBackgroundImage = true,
      enableSidebarShadow = true,
      backgroundImage = true,
      backgroundImageOpacity = true,
      userDetail = {},
    } = this.props;
    const { userRoleDetail } = this.state;
    menusToShow = Object.assign([], this.state.menusToShow);
    let permissions = [];
    if (userRoleDetail && userRoleDetail.permissions) {
      permissions = userRoleDetail.permissions;
    }
    return (
      <Fragment>
        {this.state.redirectToLoginPage && <Redirect to="/login" />}
        {this.state.isLoading && <CustomSpinner />}
        {/* <ConfirmAlert
          show={this.state.isLogoutModalOpen}
          closeModal={this.closeLogoutModal}
          title='Logout'
          warning={true}
          msg={messages.LOGOUT}
          buttons={modalButtons}
          child={null}
        /> */}
        <LogoutModal
          show={this.state.isLogoutModalOpen}
          closeModal={this.closeLogoutModal}
          history={this.props.history}
        />
        {this.state.isScorCardOpen && (
          <DownloadScorecard
            ShowDownloadScorcard={this.state.isScorCardOpen}
            closeModal={this.closeScorcardModel}
            openModel
          />
        )}

        {this.state.isRedeemCouponOpen && (
          <RedeemCoupon
            ShowDownloadScorcard={this.state.isRedeemCouponOpen}
            closeModal={this.closeRedeemCouponModel}
            openModel
          />
        )}

        <div
          className="sidebar-mobile-overlay"
          onClick={this.toggleMobileSidebar}
        />
        <ReactCSSTransitionGroup
          component="div"
          className={cx("app-sidebar", backgroundColor, {
            "sidebar-shadow": enableSidebarShadow,
          })}
          transitionName="SidebarAnimation"
          transitionAppear
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          {/* <HeaderLogo /> */}
          <PerfectScrollbar>
            <div className="app-sidebar__inner">
              <Fragment>
                {this.isMobile && (
                  <div>
                    {userRoleDetail.type !== Roles.AREA_MANAGER_ROLE ? (
                      <Fragment>
                        <div className="side-nav-profile">
                          {userDetail ? (
                            <img
                              src={
                                userDetail.image_url
                                  ? userDetail.image_url
                                  : "images/Web_Avatar.svg"
                              }
                              alt=""
                              height="50px"
                              width="auto"
                              className="side-nav-profile-img"
                              style={{
                                objectFit: "cover",
                                borderRadius: "50.5px",
                              }}
                            />
                          ) : (
                            <img
                              src="images/Web_Avatar.svg"
                              alt=""
                              height="50px"
                              width="auto"
                              className="side-nav-profile-img"
                            />
                          )}
                          <div className="d-flex-column side-nav-profile-name-wrapp">
                            {userDetail && (
                              <span className="side-nav-profile-name">
                                {userDetail.owner_name}
                              </span>
                            )}
                            <NavLink
                              exact
                              activeClassName="sidenav-active"
                              className="side-nav-profile-view"
                              to="/profile"
                              isActive={this.checkActive}
                              onClick={this.closeSidebar}
                            >
                              <span className="side-nav-profile-view">
                                View Profile
                              </span>
                            </NavLink>
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="side-nav-employee">
                          {userDetail && (
                            <div className="width-100 sidebar-title side-nav-profile-name title-case">
                              Territory Manager - {userDetail.area_name}
                            </div>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </div>
                )}

                <Nav
                  vertical
                  style={{ paddingLeft: "22px", paddingTop: "20px" }}
                >
                  {/* {prepareSideNav(menusToShow)} */}
                  <MetisMenu
                    content={Object.assign([], menusToShow)}
                    LinkComponent={CustomLink}
                    activeLinkTo={this.props.location.pathname}
                    ref="menu"
                    onSelected={this.onMenuSelected}
                    className="vertical-nav-menu"
                    classNameStateIcon="pe-7s-angle-down"
                    onMouseOut={() => this.onMouseOutMenu()}
                  />
                  {/* ==================================================================================================== */}
                  {/* ============================================================================================== */}
                  {/* <NavItem
                    className="logout"
                    onClick={this.openLogoutModal}
                    style={{
                      padding: "0px",
                      marginLeft: "-15px",
                    }}
                  >
                    <NavLink
                      activeClassName="sidenav-active"
                      className="sidenav-link"
                      style={{ display: "flex" }}
                    >
                      <span className="sidenav-icon-padding">
                        <img
                          src="images/logout_icon.png"
                          width="25px"
                          height="25px"
                          alt=""
                        />
                      </span>
                      {this.isMobile && (
                        <span
                          className="logout-lbl"
                          style={{
                            display: this.isDisplayLogOut ? "none" : "block",
                          }}
                        >
                          Logout
                        </span>
                      )}
                      {!this.isMobile && (
                        <span className="logout_title">Logout</span>
                      )}
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink exact activeClassName="sidenav-active" className="sidenav-link" to="/warrantyRegistration">
                      <span className="sidenav-icon-padding">
                        <img src="images/detail.svg" alt="" width="26px" height="25px" />
                      </span>
                      Warranty Registration
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Fragment>
            </div>
          </PerfectScrollbar>
          <div
            className={cx("app-sidebar-bg", backgroundImageOpacity)}
            style={{
              backgroundImage: enableBackgroundImage
                ? `url(${backgroundImage})`
                : null,
            }}
          />
        </ReactCSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
  updateSideMenuValue: state.ThemeOptions.updateSideMenu,
  userDetail: state.User.user,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setUserDetail: (user) => dispatch(setUserDetail(user)),
  setAddCustomerRedirect: (redirectDetailsAddCustomer) =>
    dispatch(setAddCustomerRedirect(redirectDetailsAddCustomer)),
  setRedirect: (redirectDetails) => dispatch(setRedirect(redirectDetails)),
  updateSideMenu: (enable) => dispatch(updateSideMenu(enable)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppSidebar));
