import { request } from './Request';
import { routes } from '../constants/constant.route';

const API_URL = process.env.REACT_APP_API_URL;

export default class CustomerIntractionServie {

  static addCip(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.ADD_CIP}`, null, reqData, null);
  }

  static addCipDetails(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.ADD_CIP_DETAILS}`, null, reqData, null);
  }

  static getCip(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP}`, reqData, null, null);
  }

  static getCipDetail(data) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_DETAIL_SUMMARY}`, data, null, null);
  }

  static getCIPDetailById(data) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_DETAIL}`, data, null, null);
  }

  static updateCIPDetail(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.UPDATE_CIP_DETAIL}`, null, reqData, null);
  }

  static getBrand(reqdata) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_BRAND}`, reqdata, null, null);
  }

  static getBrandPattern(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_BRAND_PATTERN}`, reqData, null, null);
  }

  static sendBillSettlment(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.SEND_BILL_SETTLMENT}`, reqData, null, null);
  }


  static downloadSummary(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.DOWNLOAD_SUMMARY}`, reqData, null, null);
  }

  static getCipCategory() {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_CATEGORY}`, null, null, null);
  }

  static getInviteeClassification(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.GET_INVITE_CLASSIFICATION}`, null, reqData, null);
  }

  static getActivity(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.GET_ACTIVITY}`, null, reqData, null);
  }


  static getCipV2(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_V2}`, null, reqData, null);
  }

  static getCipSummary(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_SUMMARY}`, reqData, null, null);
  }
  static getCipCOAttendeeSummary(reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_CIP_CO_ATTENDEE_SUMMARY}`, reqData, null, null);
  }

  static addCOAttendee(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.ADD_CO_ATTENDEE}`, null, reqData, null);
  }

  static canncelCIP(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.CANCEL_CIP}`, null, reqData, null);
  }
  static deleteCIPDetail(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.DELETE_CIP_DEATIL}`, null, reqData, null);
  }

  static updateCoAttendee(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.UPDATE_CO_ATTENDEE}`, null, reqData, null);
  }


  static addCipV2(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.ADD_CIP_V2}`, null, reqData, null);
  }

  static deleteCIPCoAttendee(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.DELETE_CIP_CO_ATTENDEE}`, null, reqData, null);
  }
  static endCIP(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.END_CIP}`, null, reqData, null);
  }
  static updateCIPDetailV2(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.UPDATE_CIP_DETAIL_V2}`, null, reqData, null);
  }
  static getTruckApm() {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.GET_TRUCK_APM}`, null, null, null);
  }
  static addCipDetailsV2(reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.ADD_CIP_DETAILS_V2}`, null, reqData, null);
  }
  static updateCIPAttendance (reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.UPDATE_CIP_ATTENDANCE}`, null, reqData, null);
  }

  static manageCIPStatus (reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.MANAGE_CIP_STATUS}`, null, reqData, null);
  }

  static updateCIP (reqData) {
    return request('POST', `${API_URL}${routes.CIP_INTRACTION.UPDATE_CIP}`, null, reqData, null);
  }
  static getAreaData (reqData) {
    return request('GET', `${API_URL}${routes.CIP_INTRACTION.getAreaData}`, reqData, null, null);
  }
  
}