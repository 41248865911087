import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import CipAttendeeList from '../CIpAttendeeList/CipAttendeeList';
import CustomerIntractionServie from '../../services/CustomerIntractionService';
import CustomSpinner from '../CustomSpinner/CustomSpinner';
import swal from 'sweetalert';

class EndCIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cip_attendee_state: props.cip_attendee_state || {},
      isLoading: false
    };
  }

  handleEndCIP = () => {

    let reqData = {
      cip_id: this.state.cip_attendee_state.id,
      expenses:  this.state.cip_attendee_state.expenses,
      type : "end_cip"
    };
    this.setState({ isLoading: true });
    CustomerIntractionServie.sendBillSettlment(reqData).then((res) => {
    
      if (res.status === 200) {

        
      CustomerIntractionServie.endCIP({...this.state.cip_attendee_state , status : "Ended"}).then((res) => {
        swal({
                title: "Success",
                text: "CIP has been successfully ended.",
                icon: "success",
                type: "success",
              }).then((value) => {
                this.setState({ isLoading: false });
                this.props.goToCipSummary();
              });

      }).catch((err) => {
        console.log(err);
          this.setState({ isLoading: false });
      });

      }
    }).catch((err) => {
      this.setState({ isLoading: false });
      console.log(err);
    });
  };

  render() {
    
    return (
      <div>
        {this.state.isLoading && <CustomSpinner />}
        <Row>
          <Col xl="12" md="12" xs="12">
            <div className="d-flex-row item-center mb-3" style={{alignItems :"center", gap :"1rem" }} > 
              <img
                src="images/right.svg"
                alt=""
                style={{ transform: "rotate(180deg)" }}
                onClick={() =>  this.props.goToCipSummary()}
              />
              <span
                onClick={ () =>  this.props.goToCipSummary()}
                className="Merchandise-catalogu-link cursor-pointer"
                style={{ fontSize: "16px" }}
              >
                Back
              </span>

              <p style={{fontSize : "20px" , marginLeft :"10px" , marginTop :"1.2rem" , fontWeight :"bold"}} >
              Mark Attendance 
              </p>
            </div>
            
             <div className="d-flex justify-content-between align-items-center ml-2 mb-2">
               <p className="text-muted mb-0" style={{ fontSize: "16px" }}>
                 Note: To close this CIP, please ensure all attendees are marked as present
               </p>
               <button 
                 className="btn btn-warning btn-pill action-button font-weight-bold"
                 style={{ width: "185px" }}
                 onClick={ () =>  this.props.goToCipSummary()}
               >
                 Save Draft
               </button>
             </div>
          </Col>
          <Col xl="12" md="12" xs="12">
            <CipAttendeeList
              cip_attendee_state={this.state.cip_attendee_state}
              goToAddParticapant={this.props.goToAddParticapant}
              openCoAttendeeList={this.props.openCoAttendeeList}
              goToCipSummary={this.props.goToCipSummary}
              isEndCIP={true}
              endCIP={this.handleEndCIP}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default EndCIP;
