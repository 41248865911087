import React, { Component } from 'react';
import {
  Row, Col,
  Button, ModalBody, Modal, Input, Label
} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "react-image-lightbox/style.css";
import CustomSpinner from '../CustomSpinner/CustomSpinner';

class RejectModificationCIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      remark: '',
      isLoading: false,
      modalType: props.modalType || 'reject' // Default to reject if not specified
    }
  }

  toggle = (row = {}, modalType = 'reject') => {
    if (!this.state.visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    this.setState({
      visible: !this.state.visible,
      cip_detail: row,
      remark: '',
      modalType
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit = () => {
    if (!this.state.remark) {
      return;
    }
    const data = {
      ...this.state.cip_detail,
      remark: this.state.remark,
      type: this.props.modalType
    }

    this.props.handleSubmit(data);
    this.toggle();
  }

  render() {
    const { modalType } = this.props;
    const isReject = modalType === 'reject';
    
    return (
      <Modal
        isOpen={this.state.visible}
        toggle={this.toggle}
        className="modal-md modal-lg custom-confirm-modal modal-sm modal-dialog-centered"
        style={{ maxWidth: "516px" }}
        backdrop={this.backdrop ? "static" : "nostatic"}
      >
        {this.state.isLoading && <CustomSpinner />}
        <div className="d-flex-column">
          <div className="confirm-modal-title-wrapp">
            <span className="confirm-modal-title">{isReject ? 'Reject CIP' : 'Modify CIP'}</span>
            <FontAwesomeIcon className="pull-right cursor-pointer" onClick={() => this.toggle({})} icon={faTimes} style={{ fontSize: '20px', cursor: 'pointer' }} color="#fdbb1f" />
          </div>
        </div>
        <ModalBody style={{ padding: '1.1rem' }}>
          <Row>
            <Col xl="12" xs="12" md="12" className="mt-2 mb-4">
              <Label>Remark <span className="text-danger">*</span></Label>
              <Input
                type="textarea"
                name="remark"
                value={this.state.remark}
                onChange={this.handleChange}
                placeholder="Enter remark"
                rows={4}
              />
            </Col>
            <Col xl="12" md="12" xs="12">
              <div className="d-flex justify-content-center mt-3 mb-2">
                <Button
                  className={`mb-1 mr-1 btn-pill action-button profile-save-button`}
                  style={{width :"100%"}}
                  color={'warning'}
                  onClick={this.handleSubmit}
                  disabled={!this.state.remark}
                >
                  {isReject ? 'Reject' : 'Send for Modification'}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default RejectModificationCIP;
