import { Permissions } from '../constants/constant.permissions';
const monthObj = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec"
};

const quarterObj = {
  1: ["Jan", "Feb", "Mar"],
  2: ["Apr", "May", "Jun"],
  3: ["Jul", "Aug", "Sep"],
  4: ["Oct", "Nov", "Dec"]
};

const mapMonthToQuarter = {
  1: 4,
  2: 4,
  3: 4,
  4: 1,
  5: 1,
  6: 1,
  7: 2,
  8: 2,
  9: 2,
  10: 3,
  11: 3,
  12: 3,
}

const processEnv = process.env;

export function checkUserAgent() {
  const ua = navigator.userAgent;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      ua
    )
  ) {
    return true;
  } else if (/Chrome/i.test(ua)) {
    return false;
  } else {
    return false;
  }
}

export function formatNumber(number) {
  number = parseInt(number, 0);
  if (typeof number === "number" && !Number.isNaN(number)) {
    return Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 20
    }).format(number);
  }
  return 0;

}

export function findMonthFromFirstQToPreviousQMonths() {
  // let quarterObj = {
  //   1: ["Jan", "Feb", "March"],
  //   2: ["Apr", "May", "Jun"],
  //   3: ["Jul", "Aug", "Sept"],
  //   4: ["Oct", "Nov", "Dec"]
  // }

  const { prevYear,
    // currentYear
  } = findPreviousAndCurrentYear();
  // currentYear = "`" + currentYear.slice(2);
  let q = new Date().getMonth() / 3 + 1;
  q = Math.floor(q);
  q -= 1;
  if (q === 0) {
    q = 4;
  }
  const currentQuarterMonths = quarterObj[q];
  const str = `${quarterObj[2][0]}${prevYear}-${currentQuarterMonths[2]}${prevYear}`;
  return str;
}

export function findCurrentQuarterMonths(date) {
  // let quarterObj = {
  //   1: ["Jan", "Feb", "Mar"],
  //   2: ["Apr", "May", "Jun"],
  //   3: ["Jul", "Aug", "Sep"],
  //   4: ["Oct", "Nov", "Dec"]
  // }
  let dateToProcess = date ? date : new Date();
  let currentYear = dateToProcess.getFullYear().toString();
  currentYear = `'${currentYear.slice(2)}`;
  let q = ((dateToProcess.getMonth() / 3) + 1);
  q = Math.floor(q);
  const currentQuarterMonths = quarterObj[q];
  const str = `${currentQuarterMonths[0]}${currentYear}-${currentQuarterMonths[2]}${currentYear}`;
  return str;
}

export function findPreviousQuarterMonths() {
  // let quarterObj = {
  //   1: ["Jan", "Feb", "Mar"],
  //   2: ["Apr", "May", "Jun"],
  //   3: ["Jul", "Aug", "Sep"],
  //   4: ["Oct", "Nov", "Dec"]
  // }
  // let currentYear = new Date().getFullYear().toString();
  // currentYear = "`" + currentYear.slice(2);
  const { prevYear,
    // currentYear
  } = findPreviousAndCurrentYear();
  let q = new Date().getMonth() / 3 + 1;
  q = Math.floor(q);
  q -= 1;
  if (q === 0) {
    q = 4;
  }
  const currentQuarterMonths = quarterObj[q];
  const str = `${currentQuarterMonths[0]}${prevYear}-${currentQuarterMonths[2]}${prevYear}`;
  return str;
}


export function findFirstQuarterToCurrentMonths(date) {

  // let currentYear = new Date().getFullYear().toString();
  let currentDateToProcess = date ? date : new Date();

  const currentMonth = currentDateToProcess.getMonth() + 1;

  // currentYear = "`" + currentYear.slice(2);
  const { prevYear, currentYear } = findPreviousAndCurrentYear(date);
  let q = ((new Date().getMonth() / 3) + 1);
  q = Math.floor(q);
  // let currentQuarterMonths = quarterObj[q];
  const str = `${quarterObj[2][0]}${prevYear}-${monthObj[currentMonth]}${currentYear}`;
  return str;
}

export function getCurrentMonth(date) {
  // const date = new Date();
  const dateToProcess = date ? date : new Date();
  const month = dateToProcess.getMonth() + 1;
  let year = dateToProcess.getFullYear().toString();
  year = getYearString(year);
  return `${monthObj[month]}${year}`;
}


export function getCurrentQuarterMonths(date) {
  const dateToProcess = date ? date : new Date();
  let q = ((dateToProcess.getMonth() / 3) + 1);
  q = Math.floor(q);
  // const date = dateToProcess;
  const month = dateToProcess.getMonth() + 1;
  let year = dateToProcess.getFullYear().toString();
  year = getYearString(year);

  return `${quarterObj[q][0]}${year}-${monthObj[month]}${year}`;
}

export function getCurrentQuarter(date) {
  const dateToProcess = date ? date : new Date();
  const month = dateToProcess.getMonth() + 1;
  return mapMonthToQuarter[month];
}

export function getCurrentYear(date) {
  const dateToProcess = date ? date : new Date();
  return dateToProcess.getFullYear();
}

export function formatDate(date) {
  date = new Date(date);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear().toString();
  year = getYearString(year);
  if (day < 10) {
    day = `0${day}`;
  }
  month = monthObj[month];
  return `${day} ${month}${year}`;
}

export function getYearString(year) {
  if (!year) {
    year = new Date().getFullYear().toString();
  }
  return `'${year.slice(2)}`;
}

export function getYearRangeString(year) {
  if (year === null || year === '' || year === undefined) {
    return ''
  }
  year = parseInt(year);
  if (year === NaN) {
    return '';
  }
  const startYear = year;
  const endYear = year + 1;
  const endYearString = endYear.toString().slice(2);
  return `${startYear}-${endYearString}`;
}

function findPreviousAndCurrentYear(date) {
  let currentYear = '';
  let prevYear = '';
  const dateToProcess = date ? date : new Date();
  // const currentDate = new Date();
  const currentMonth = dateToProcess.getMonth() + 1;

  currentYear = dateToProcess.getFullYear();
  prevYear = dateToProcess.getFullYear();
  if (currentMonth < 4) {
    prevYear = currentYear - 1;
  }

  prevYear = prevYear.toString();
  currentYear = currentYear.toString();
  prevYear = getYearString(prevYear);
  currentYear = getYearString(currentYear);
  return { prevYear, currentYear };
}

export function downlodFile(url) {
  // let blob = new Blob([b.data], { type: b.headers['content-type'] });
  // const url = URL.createObjectURL(blob, {});
  // const url = b.data.data.file;
  // const url = window.URL.createObjectURL(new Blob([b]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute('download', 'sample.xlsx');
  link.setAttribute('target', '_blank');
  // 3. Append to html page
  document.body.appendChild(link);
  // 4. Force download
  link.click();
  // 5. Clean up and remove the link
  link.parentNode.removeChild(link);
}

/**
 * Check SMS Credits And SMS Count.
 * @param {text} text sms text.
 * @param {number} maxCount maxcount
 * @param {boolean} isEnglish English or Other language
 * @param {boolean} isPictureSMS Simple SMS Or Picture SMS
 */

export function changeSMSContent(text = "", maxCount, isEnglish, isPictureSMS) {
  if (text === null || text === undefined) {
    text = '';
  }
  if (isPictureSMS) {
    if (processEnv.NODE_ENV === "production") {
      text +=
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"; // if picture sms then add 32 space
    } else {
      text +=
        "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"; // if picture sms then add 32 space
    }
  } else {
    if (processEnv.NODE_ENV === "production") {
      text += text.slice(-1, -19);
    } else {
      text += text.slice(-1, -33);
    }
  }
  let length = text.length;
  let validMinCount = maxCount - length;
  const totalOccurence = (text.match(new RegExp("\n", "g")) || []).length;
  if (totalOccurence > 0) {
    length = length + totalOccurence;
    validMinCount = maxCount - length;
  }
  if (text.includes('#GMBStoreLink') === true) {
    length = length - ('#GMBStoreLink').length;
    let url = window.location.href.split('#/');
    length = length + url[0].length + '#/b/dealer'.length;
  }
  let smsContentCredit = 0;
  if (isEnglish) {
    if (length === 0) {
      smsContentCredit = 0;
    } else if (length > 0 && length < 161) {
      smsContentCredit = 1;
    } else if (length > 160 && length < 307) {
      smsContentCredit = 2;
    } else if (length > 306 && length < 460) {
      smsContentCredit = 3;
    } else if (length >= 460) {
      smsContentCredit = 4;
    }
  } else {
    if (length > 469) {
      smsContentCredit = 8;
    } else if (length > 402) {
      smsContentCredit = 7;
    } else if (length > 335) {
      smsContentCredit = 6;
    } else if (length > 268) {
      smsContentCredit = 5;
    } else if (length > 201) {
      smsContentCredit = 4;
    } else if (length > 134) {
      smsContentCredit = 3;
    } else if (length > 70) {
      smsContentCredit = 2;
    } else if (length === 0) {
      smsContentCredit = 0;
    } else {
      smsContentCredit = 1;
    }
  }
  return {
    length: length,
    isNotValidMinCount: validMinCount >= 0 ? false : true,
    smsContentCredit: smsContentCredit
  };
}

export function generateRandomString() {
  return Math.random()
    .toString(36)
    .substring(2);
}

export function roundValue(number) {
  return Math.round(number)
}

export function getScreenResolution() {
  return `${window.screen.availWidth}x${window.screen.availHeight}`
}




export function getAction(action) {
  let pathname = "/";
  switch (action) {
    case "dashboard":
    case "dashboard_ytd":
    case "dashboard_mtd":
    case "dashboard_qtd":
    case "yearly_offtake":
    case "check_warranty_eligibility_warranty":
    case "add_warranty":
      pathname = "/";
      break;
    case "report":
      pathname = "/primary-points-report";
      break;

    case "profile":
    case "profile_request_to_edit":
      pathname = "/profile";
      break;

    case "about_programme":
      pathname = "/about";
      break;

    case "warranty_scorecard":
      pathname = "/warranty-score-card";
      break;

    case "merchandise":
      pathname = "/merchandise-wallet";
      break;

    case "employee_dashboard":
    case "employee_dashboard_mtd":
    case "employee_dashboard_ytd":
    case "employee_dashboard_qtd":
    case "employee_yearly_offtake":
      pathname = "/dashboard";
      break;

    case "employee_warranty_scorecard":
      pathname = "/warranty-score-card-report";
      break;

    case "contact_us":
      pathname = "/contact-us";
      break;

    case "employee_offtake_scorecard":
      pathname = "/offtake-score-card-report";
      break;
    case "trade_scheme":
    case "trade-scheme":
      pathname = "/trade-scheme";
      break;
    case "gift-catalogue":
    case "catalogue":
      pathname = "/redemption-catalog";
      break;
    case "merchandise-catalogue":
    case "merchandise_wallet":
      pathname = "/merchandise-wallet";
      break;
    case "redeem_coupon":
      pathname = {
        isRedeemCouponOpen: true
      };
      break;
    case "redeem_coupon_report":
      pathname = "/redeem-coupon-report";
      break;
    case "manage_group":
      pathname = "/manage-group";
      break;
    case "customer_management":
      pathname = "/customer-management";
      break;
    case "customer_list":
      pathname = "/customer-management";
      break;
    case "invite_branch_sub_dealer":
      pathname = "/invite-dealer";
      break;
    case "add_customer":
      pathname = {
        redirectToAddCustomer: true,
        pathname: this.state.userDetail.permissions && this.state.userDetail.permissions.includes(Permissions.DEALER_NEW_DASHBOARD) ? '/dealer-dashboard' : '/'
      };
      break;
    case "campaign":
      pathname = "/campaign/create";
      break;
    case "create_campaign":
      pathname = "/campaign/create";
      break;
    case "notification_preference":
      pathname = "/notification-preference";
      break;
    case "service_preferance":
      pathname = "/notification-preference";
      break;
    case "buy_sms":
    case "sms":
      pathname = {
        buySMS: true,
        pathname: "/campaign/create"
      };
      break;
    case "order_history":
      pathname = "/order-history";
      break;
    case "poster_download":
      pathname = "/download_poster";
      break;
    case "LOW_BALANCE":
      pathname = {
        buySMS: true,
        pathname: "/campaign/create"
      };
      break;
    default:
      pathname = "/";
      break;
  }
  return pathname;
}

export const maskEmail = (email) => {
  if (!email || typeof email !== "string") return "";

  // Basic email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(email)) return "";

  const [local, domain] = email.split("@");
  if (!local || !domain) return "";

  const maskedLocal = local.length > 2
    ? `${local[0]}${"*".repeat(local.length - 2)}${local[local.length - 1]}`
    : `${local[0]}*`;

  const domainParts = domain.split(".");
  const maskedDomain = domainParts[0].length > 2
    ? `${domainParts[0][0]}${"*".repeat(domainParts[0].length - 2)}${domainParts[0][domainParts[0].length - 1]}`
    : `${domainParts[0][0]}*`;

  return `${maskedLocal}@${maskedDomain}.${domainParts.slice(1).join(".")}`;
};

const utils = {
  checkUserAgent,
  maskEmail,
};

export default utils;
