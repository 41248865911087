import React, { Component } from 'react';
import CipDealerDetail from '../../components/CipDealerDetail/CipDealerDetail';
import {
  Row, Col,
} from 'reactstrap';

class CipRegitration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cip_details: this.props.location && this.props.location.state ? this.props.location.state.cip_details : null,
      isEdit: this.props.location && this.props.location.state && this.props.location.state.cip_details ? true : false
    }
  }

  backToCipSummary = () => {
    this.goToCipSummary()
  }

  goToCipSummary = () => {
    this.props.history.push({
      pathname: "/cip-summary",
      isDisplayAddParticipantPopover: true
    });
  }

  render() {
    const { cip_details, isEdit } = this.state;

    return (
      <div>
        <div className="d-flex-row mt-2">
          <span onClick={() => this.backToCipSummary()} className="Merchandise-catalogu-link cursor-pointer" style={{ fontSize: '16px' }}>CIP Summary</span>&nbsp;&nbsp;
          <img src="images/right.svg" alt="" />&nbsp;&nbsp;
          <span className="cart-lbl" style={{ fontSize: '24px', width: 'auto' }}>
            {isEdit ? 'Edit CIP' : 'CIP Registration'}
          </span>
        </div>
        <Row>
          <Col xl="9" md="12" xs="12">
            <CipDealerDetail
              goToCipSummary={this.goToCipSummary}
              cip_details={cip_details}
              isEdit={isEdit}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default CipRegitration;