import React, { Component } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import lodash from "lodash";
import ConfirmationNextAtendee from "../ConfirmationNextAtendee/ConfirmationNextAtendee";
import CustomerIntractionAddEditVehicle from "../../components/CustomerIntractionAddEditVehicle/CustomerIntractionAddEditVehicle";
import Validation from "../../services/Validation";
import MerchandiseWalletService from "../../services/MerchandiseWalletService";
import CustomerIntractionServie from "../../services/CustomerIntractionService";
import swal from "sweetalert";
import * as Scroll from "react-scroll";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import ModalService from "../../services/ModalService";
import TextareaAutosize from "react-textarea-autosize";

const defaultTyreObj = {
  construction: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  front_or_rear: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  size: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brand_id: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  pattren: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  tyres: {
    value: "",
    valid: null,
    touched: false,
    nullValue: null,
    showErrorMsg: false,
  },
  brands: [],
  pattern: [],
};
class CipBusinessDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isJKuserDeatil: false,
      controls: {
        company_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        attendee_name: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        designation: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        mobile_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        whatsapp_number: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        email: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        address: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        same_as_mobile: {
          value: false,
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        state: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        city: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
        pin_code: {
          value: "",
          valid: null,
          touched: false,
          nullValue: null,
          showErrorMsg: false,
        },
      },
      vehicleData: {},
      stateData: [],
      cityData: [],
      brands: [],
      isFormValid: false,
      isLoading: false,
      temp_mobile_number: "",
    };

    if (
      this.props.CipBusinessDetailControls &&
      Object.keys(this.props.CipBusinessDetailControls).length > 0
    ) {
      this.state.controls = this.props.CipBusinessDetailControls;
    }
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getState();
    if (this.props.cip_uuid) {
      this.getCIPDetailById();
    }
    if (
      this.props.CipBusinessDetailControls &&
      Object.keys(this.props.CipBusinessDetailControls).length > 0
    ) {
      this.getCity();
    }
  }

  setMobileNo = (mobile_number) => {
    let { controls } = this.state;
    controls.mobile_number.value = mobile_number;
    this.setState({
      controls,
    });
  };

  getCIPDetailById = async () => {
    let { controls } = this.state;
    this.props.getCIPDetailById(controls.mobile_number.value);
    return;
    // this.setState({ isLoading: true });
    // //let { controls } = this.state;
    // let reqData = { uuid: this.props.cip_uuid };
    // if (controls.mobile_number.value) {
    //   reqData = { mobile_number: controls.mobile_number.value };
    // }
    // CustomerIntractionServie.getCIPDetailById(reqData)
    //   .then((data) => {
    //     if (data.data.data && Object.keys(data.data.data).length > 0) {
    //       let { controls, vehicleData, temp_mobile_number } = this.state;
    //       this.props.getCoAttendeeList(data.data.data.id);
    //       temp_mobile_number = data.data.data.mobile_number;
    //       controls.mobile_number.value = data.data.data.mobile_number;
    //       controls.email.value = data.data.data.email;
    //       controls.company_name.value = data.data.data.company_name;
    //       controls.attendee_name.value = data.data.data.attendee_name;
    //       controls.designation.value = data.data.data.designation;
    //       controls.whatsapp_number.value = data.data.data.whatsapp_number;
    //       controls.state.value = data.data.data.state_id;
    //       controls.city.value = data.data.data.city_id;
    //       controls.pin_code.value = data.data.data.pin_code;
    //       controls.address.value = data.data.data.address;
    //       this.setState({
    //         controls,
    //         vehicleData,
    //         isLoading: false,
    //         temp_mobile_number,
    //       });
    //       this.getCity(false);
    //     } else {
    //       this.setState({
    //         isLoading: false,
    //       });
    //       this.props.resetDataFromMobileNo();
    //     }
    //   })
    //   .catch((e) => {
    //     let err =
    //       e.response && e.response.data && e.response.data.error[0]
    //         ? e.response.data.error[0]
    //         : null;
    //     const message = err && err.message ? err.message : "";
    //     ModalService.openAlert("", message, "error");
    //     this.setState({ isLoading: false });
    //   });
  };

  setControls = () => {
    let { controls } = this.state;
    controls = this.props.CipBusinessDetailControls;
    this.setState({
      controls,
    });
  };
  setFrontRearData = (key, data) => {
    let { controls } = this.state;
    let { frontRearData } = controls;
    data[key].forEach((element, i) => {
      frontRearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));
      frontRearData[key][i].front_or_rear.value = element.front_or_rear
        ? element.front_or_rear
        : "front_data";
      frontRearData[key][i].construction.value = element.construction
        ? element.construction
        : "";
      frontRearData[key][i].size.value = element.size ? element.size : "";
      frontRearData[key][i].brand.value = element.brand ? element.brand : "";
      if (element.brand_id) {
        this.changeFrontBrand(element.brand_id, i, key);
      }
      if (element.construction) {
        this.getBrand(element.construction, i, key, true);
      }
      frontRearData[key][i].pattren.value = element.pattren
        ? element.pattren
        : "";
      frontRearData[key][i].tyres.value = element.tyres ? element.tyres : "";
    });
    this.setState({
      controls,
    });
  };

  setRearData = (key, data) => {
    let { controls } = this.state;
    let { rearData } = controls;
    data[key].forEach((element, i) => {
      rearData[key].push(JSON.parse(JSON.stringify(defaultTyreObj)));
      rearData[key][i].construction.value = element.construction
        ? element.construction
        : "";
      rearData[key][i].size.value = element.size ? element.size : "";
      rearData[key][i].brand.value = element.brand ? element.brand : "";
      if (element.brand) {
        this.changeRearBrand(element.brand, i, key);
      }
      if (element.construction) {
        this.getBrand(element.construction, i, key, false);
      }
      rearData[key][i].pattren.value = element.pattren ? element.pattren : "";
      rearData[key][i].tyres.value = element.tyres ? element.tyres : "";
    });
    this.setState({
      controls,
    });
  };

  openAddTyreModal = (data) => {
    // this.refs.refConfirmationNextAtendee.toggle(data);
  };
  openAddEditVehicleModal = (type) => {
    this.refs.refCustomerIntractionAddEditVehicle.toggle(
      this.state.vehicleData,
      type
    );
  };

  handleValidation = (isSubmit = false) => {
    let { controls, isFormValid } = this.state;
    let {
      mobile_number,
      email,
      company_name,
      attendee_name,
      designation,
      whatsapp_number,
      state,
      city,
      pin_code,
      address,
    } = controls;

    if (company_name.touched === true || isSubmit) {
      company_name = Validation.notNullValidator(company_name);
      company_name.valid = !company_name.nullValue;
      if (company_name.valid === false) {
        company_name.showErrorMsg = true;
      } else {
        company_name.showErrorMsg = false;
      }
    }

    if (address.touched === true || isSubmit) {
      address = Validation.notNullValidator(address);
      address.valid = !address.nullValue;
      if (address.valid === false) {
        address.showErrorMsg = true;
      } else {
        address.showErrorMsg = false;
      }
    }

    if (attendee_name.touched === true || isSubmit) {
      attendee_name = Validation.notNullValidator(attendee_name);
      attendee_name.valid = !attendee_name.nullValue;
      if (attendee_name.valid === false) {
        attendee_name.showErrorMsg = true;
      } else {
        attendee_name.showErrorMsg = false;
      }
    }

    if (designation.touched === true || isSubmit) {
      designation = Validation.notNullValidator(designation);
      designation.valid = !designation.nullValue;
      if (designation.valid === false) {
        designation.showErrorMsg = true;
      } else {
        designation.showErrorMsg = false;
      }
    }

    if (state.touched === true || isSubmit) {
      state = Validation.notNullValidator(state);
      state.valid = !state.nullValue;
      if (state.valid === false) {
        state.showErrorMsg = true;
      } else {
        state.showErrorMsg = false;
      }
    }

    if (city.touched === true || isSubmit) {
      city = Validation.notNullValidator(city);
      city.valid = !city.nullValue;
      if (city.valid === false) {
        city.showErrorMsg = true;
      } else {
        city.showErrorMsg = false;
      }
    }

    if (pin_code.touched === true || isSubmit) {
      pin_code = Validation.notNullValidator(pin_code);
      pin_code = Validation.validateLength(pin_code, 6);
      pin_code = Validation.validateNumber(pin_code);
      pin_code.valid = !(
        pin_code.nullValue ||
        pin_code.invalidLength ||
        pin_code.invalidNumber
      );
      if (!pin_code.valid) {
        pin_code.showErrorMsg = true;
      } else {
        pin_code.showErrorMsg = false;
      }
    }

    if (mobile_number.touched === true || isSubmit) {
      mobile_number = Validation.notNullValidator(mobile_number);
      if (!mobile_number.nullValue) {
        mobile_number = Validation.validatePhoneNumber(mobile_number);
      }
      mobile_number.valid = !(
        mobile_number.nullValue || mobile_number.invalidPhone
      );
      if (mobile_number.valid === false) {
        mobile_number.showErrorMsg = true;
      } else {
        mobile_number.showErrorMsg = false;
      }
    }

    if (whatsapp_number.touched === true || isSubmit) {
      whatsapp_number = Validation.validatePhoneNumber(whatsapp_number);
      whatsapp_number.valid = !whatsapp_number.invalidPhone;
      if (whatsapp_number.valid === false) {
        whatsapp_number.showErrorMsg = true;
      } else {
        whatsapp_number.showErrorMsg = false;
      }
    }

    email.valid = true;
    if (email.touched === true || isSubmit) {
      if (
        email.value === "" ||
        email.value === null ||
        email.value === undefined
      ) {
        email.valid = true;
        email.showErrorMsg = false;
      } else {
        email = Validation.emailValidator(email);
        email.valid = !email.invalidEmail;
        if (email.valid === false) {
          email.showErrorMsg = true;
        } else {
          email.showErrorMsg = false;
        }
      }
    }

    if (
      email.valid === true &&
      mobile_number.valid === true &&
      attendee_name.valid === true &&
      designation.valid === true &&
      whatsapp_number.valid === true &&
      state.valid === true &&
      city.valid === true &&
      pin_code.valid === true &&
      address.valid === true
    ) {
      isFormValid = true;
    } else {
      isFormValid = false;
    }
    this.setState({ controls, isFormValid });
    return isFormValid;
  };

  handleValidationFrontData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        if (currentData.construction.touched === true || isSubmit) {
          currentData.construction = Validation.notNullValidator(
            currentData.construction
          );
          currentData.construction.valid = !!currentData.construction.value;
        }

        if (currentData.size.touched === true || isSubmit) {
          currentData.size = Validation.notNullValidator(currentData.size);
          currentData.size.valid = !!currentData.size.value;
        }

        if (currentData.brand.touched === true || isSubmit) {
          currentData.brand = Validation.notNullValidator(currentData.brand);
          currentData.brand.valid = !!currentData.brand.value;
        }

        if (currentData.pattren.touched === true || isSubmit) {
          currentData.pattren = Validation.notNullValidator(
            currentData.pattren
          );
          currentData.pattren.valid = !!currentData.pattren.value;
        }

        if (currentData.tyres.touched === true || isSubmit) {
          currentData.tyres = Validation.notNullValidator(currentData.tyres);
          currentData.tyres.valid = !!currentData.tyres.value;
        }
      }
    }
    let isTyreDetailValid = true;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "front_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          currentData.construction.valid &&
          currentData.size.valid &&
          currentData.brand.valid &&
          currentData.pattren.valid &&
          currentData.tyres.valid;
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleValidationRearData = (key = "", isSubmit = false) => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        if (currentData.construction.touched === true || isSubmit) {
          currentData.construction = Validation.notNullValidator(
            currentData.construction
          );
          currentData.construction.valid = !!currentData.construction.value;
        }

        if (currentData.size.touched === true || isSubmit) {
          currentData.size = Validation.notNullValidator(currentData.size);
          currentData.size.valid = !!currentData.size.value;
        }

        if (currentData.brand.touched === true || isSubmit) {
          currentData.brand = Validation.notNullValidator(currentData.brand);
          currentData.brand.valid = !!currentData.brand.value;
        }

        if (currentData.pattren.touched === true || isSubmit) {
          currentData.pattren = Validation.notNullValidator(
            currentData.pattren
          );
          currentData.pattren.valid = !!currentData.pattren.value;
        }

        if (currentData.tyres.touched === true || isSubmit) {
          currentData.tyres = Validation.notNullValidator(currentData.tyres);
          currentData.tyres.valid = !!currentData.tyres.value;
        }
      }
    }
    let isTyreDetailValid = true;
    for (let i = 0; i < frontRearData[key].length; i++) {
      const currentData = frontRearData[key][i];
      if (currentData.front_or_rear.value === "rear_data") {
        isTyreDetailValid =
          isTyreDetailValid &&
          currentData.construction.valid &&
          currentData.size.valid &&
          currentData.brand.valid &&
          currentData.pattren.valid &&
          currentData.tyres.valid;
      }
    }
    let isValidForm = false;
    if (isTyreDetailValid) {
      isValidForm = true;
    } else {
      isValidForm = false;
    }
    this.setState({ controls });
    return isValidForm;
  };

  handleInputChange = async (e) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    if (controlName === "same_as_mobile") {
      controls.same_as_mobile.value = !controls.same_as_mobile.value;
      if (
        !controls.mobile_number.showErrorMsg &&
        controls.mobile_number.value &&
        controls.same_as_mobile.value
      ) {
        controls.whatsapp_number.value = controls.mobile_number.value;
        controls.same_as_mobile.value = true;
      } else {
        controls.whatsapp_number.value = "";
        controls.same_as_mobile.value = false;
      }
    } else {
      controls[controlName].value = controlValue;
      controls[controlName].touched = true;
    }
    await this.setState({ controls });
    this.handleValidation();
    if (controlName === "state") {
      this.getCity();
    }
  };

  handleOnBlurMobile = () => {
    let { controls, temp_mobile_number } = this.state;
    if (controls.mobile_number.valid) {
      if (controls.same_as_mobile.value) {
        controls.whatsapp_number.value = controls.mobile_number.value;
      }

      if (
        temp_mobile_number &&
        temp_mobile_number !== controls.mobile_number.value
      ) {
      } else {
        this.getCIPDetailById();
      }
    }
  };

  resetData = () => {
    let { controls } = this.state;
    // controls.mobile_number.value = "";
    // controls.mobile_number.touched = false;
    controls.email.value = "";
    controls.email.touched = false;
    controls.company_name.value = "";
    controls.company_name.touched = false;
    controls.attendee_name.value = "";
    controls.attendee_name.touched = false;
    controls.designation.value = "";
    controls.designation.touched = false;
    controls.whatsapp_number.value = "";
    controls.whatsapp_number.touched = false;
    controls.state.value = "";
    controls.state.touched = false;
    controls.city.value = "";
    controls.city.touched = false;
    controls.pin_code.value = "";
    controls.pin_code.touched = false;
    controls.address.value = "";
    controls.same_as_mobile.value = false;
    this.setState({
      controls,
    });
  };

  checkAllDataValidationFront = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationFrontData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationFrontData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationFrontData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationFrontData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };

  checkAllDataValidationRear = () => {
    let frontTruck = true;
    let frontBus = true;
    let frontLCV = true,
      frontSCV = true;
    if (!this.handleValidationRearData("truck", true)) {
      frontTruck = false;
    }
    if (!this.handleValidationRearData("bus", true)) {
      frontBus = false;
    }
    if (!this.handleValidationRearData("lcv", true)) {
      frontLCV = false;
    }
    if (!this.handleValidationRearData("scv", true)) {
      frontSCV = false;
    }
    return !!(
      frontTruck === true &&
      frontBus === true &&
      frontSCV === true &&
      frontLCV === true
    );
  };

  getReqFrontData = () => {
    let { controls } = this.state;
    const { frontRearData } = controls;
    let resFrontData = {
      bus: [],
      truck: [],
      lcv: [],
      scv: [],
    };
    Object.keys(frontRearData).forEach((key, i) => {
      frontRearData[key].forEach((element) => {
        resFrontData[key].push({
          construction: element.construction.value,
          front_or_rear: element.front_or_rear.value,
          size: element.size.value,
          brand: element.brand.value,
          pattren: element.pattren.value,
          tyres: element.tyres.value ? element.tyres.value : "",
          brand_id: element.brand_id.value ? element.brand_id.value : "",
        });
      });
    });
    return resFrontData;
  };

  submitDetail = (isSubmit = false) => {
    // if (!this.handleValidation(isSubmit)) {
    //   return
    // }
    this.props.submitDetailBusinessDetail(this.state.controls);

    return;
    let { controls, vehicleData } = this.state;
    let reqData = {
      company_name: controls.company_name.value,
      attendee_name: controls.attendee_name.value,
      designation: controls.designation.value,
      mobile_number: controls.mobile_number.value,
      whatsapp_number: controls.whatsapp_number.value
        ? controls.whatsapp_number.value
        : null,
      email: controls.email.value,
      state_id: controls.state.value,
      city_id: controls.city.value,
      pin_code: controls.pin_code.value,
      address: controls.address.value,
    };
    if (this.props.cip_id) {
      reqData.cip_id = this.props.cip_id;
    }
    if (Object.keys(vehicleData).length > 0) {
      reqData.vehicle_detail = vehicleData.vehicles;
      reqData.business_related_detail = this.getReqFrontData();
    }

    if (this.props.cip_uuid) {
      reqData.uuid = this.props.cip_uuid;
      this.updateCIPDetail(reqData);
      return;
    }

    this.setState({ isLoading: true });
    CustomerIntractionServie.addCipDetails(reqData)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => { });
        // if (isNextAttende) {
        //   this.resetData();
        // } else {
        //   this.props.endUpRegistration(false);
        // }
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  updateCIPDetail = (reqData) => {
    this.setState({ isLoading: true });
    CustomerIntractionServie.updateCIPDetail(reqData)
      .then((res) => {
        swal({
          title: "",
          text: res.data.message,
          icon: "success",
          type: "success",
        }).then((value) => {
          this.props.endUpRegistration(true);
        });
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  changeRearBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.pattern = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  changeFrontBrand = (brand, index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    const tyreObj = frontRearData[key][index];
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrandPattern({ brand_id: brand })
      .then((res) => {
        tyreObj.pattern = res.data.data.pattern;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };

  onChangeFrontRearTyre = (e, index, key, brands = []) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { frontRearData } = controls;
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    const tyreObj = frontRearData[key][index];
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.brand_id.value = "";
      tyreObj.pattren.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattren.value = "";
    }
    if (controlName === "brand") {
      let brand = lodash.filter(brands, (element) => {
        return element.name === controlValue;
      });
      if (brand.length > 0) {
        tyreObj.brand_id.value = brand[0].id;
        this.changeFrontBrand(brand[0].id, index, key);
      } else {
        tyreObj.brand_id.value = "other";
        this.changeFrontBrand("other", index, key);
      }
    }
    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, true);
    }
    this.setState({
      controls,
    });
    this.handleValidationFrontData(key);
  };

  onChangeRearTyre = (e, index, key) => {
    const controlName = e.target.name;
    const controlValue = e.target.value;
    const { controls } = this.state;
    const { rearData } = controls;
    const tyreObj = rearData[key][index];
    if (controlName === "tyres") {
      if (isNaN(controlValue)) {
        return;
      }
    }
    tyreObj[controlName].value = controlValue;
    tyreObj[controlName].touched = true;
    if (controlName === "construction") {
      tyreObj.size.value = "";
      tyreObj.brand.value = "";
      tyreObj.pattren.value = "";
    } else if (controlName === "brand") {
      tyreObj.pattren.value = "";
    }
    if (controlName === "brand") {
      this.changeRearBrand(controlValue, index, key);
    }

    if (controlName === "construction") {
      this.getBrand(controlValue, index, key, false);
    }

    this.setState({
      controls,
    });
    this.handleValidationRearData(key);
  };

  addFrontTyreObject = (key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "front_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    this.setState({ controls });
  };

  removeFrontTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  addRearTyreObject = (key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    let setdefaultTyreObj = defaultTyreObj;
    setdefaultTyreObj.front_or_rear.value = "rear_data";
    frontRearData[key].push(JSON.parse(JSON.stringify(setdefaultTyreObj)));
    this.setState({ controls });
  };

  removeRearTyre = (index, key) => {
    const { controls } = this.state;
    const { frontRearData } = controls;
    frontRearData[key].splice(index, 1);
    this.setState({ controls });
  };

  submitVehclies = (data) => {
    let vehicleData = lodash.cloneDeep(data);
    if (Object.keys(vehicleData).length <= 0) {
      this.setState({
        vehicleData: {},
      });
      return;
    }
    this.setState({ isLoading: true });
    let { controls } = this.state;
    let { frontRearData, rearData } = controls;
    if (vehicleData.totalCountTruck > 0) {
      if (frontRearData.truck.length === 0) {
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[0].front_or_rear.value = "front_data";
        frontRearData.truck.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.truck[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["truck"] = [];
    }
    if (vehicleData.totalCountBus > 0) {
      if (frontRearData.bus.length === 0) {
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[0].front_or_rear.value = "front_data";
        frontRearData.bus.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.bus[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["bus"] = [];
    }
    if (vehicleData.totalCountLcv > 0) {
      if (frontRearData.lcv.length === 0) {
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[0].front_or_rear.value = "front_data";
        frontRearData.lcv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.lcv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["lcv"] = [];
    }
    if (vehicleData.totalCountScv > 0) {
      if (frontRearData.scv.length === 0) {
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[0].front_or_rear.value = "front_data";
        frontRearData.scv.push(JSON.parse(JSON.stringify(defaultTyreObj)));
        frontRearData.scv[1].front_or_rear.value = "rear_data";
      }
    } else {
      frontRearData["scv"] = [];
    }
    this.setState({ isLoading: false });
    this.setState({
      vehicleData: vehicleData,
      frontRearData,
      rearData,
    });
  };

  getState = () => {
    this.setState({ isLoading: this.props.cip_id ? false : true });
    MerchandiseWalletService.getState()
      .then((data) => {
        let { stateData, controls } = this.state;
        let { data: states } = data.data;
        stateData = states;
        this.setState({ stateData, controls, isLoading: false });
      })
      .catch((e) => {
        this.setState({ isLoading: false });
      });
  };

  getCity = (isLoader = true) => {
    let { controls } = this.state;
    if (isLoader) {
      this.setState({ isLoading: true });
    }
    MerchandiseWalletService.getCity(controls.state.value)
      .then((data) => {
        let { cityData, controls } = this.state;
        const { data: cities } = data.data;
        cityData = cities;
        this.setState({ cityData, controls });
        if (isLoader) {
          this.setState({ isLoading: false });
        }
      })
      .catch((e) => {
        console.error("e", e);
        if (isLoader) {
          this.setState({ isLoading: false });
        }
      });
  };

  scrollTo(id) {
    var scroller = Scroll.scroller;
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 100,
      smooth: "easeInOutQuint",
      offset: -80,
    });
  }

  confirmationNextAtendee = () => {
    if (!this.handleValidation(true)) {
      this.scrollTo("business-detail");
      return true;
    }

    // if (!this.checkAllDataValidationFront()) {
    //   return false;
    // }
    // if (!this.checkAllDataValidationRear()) {
    //   return false;
    // }
    this.refs.refConfirmationNextAtendee.toggle();
  };

  getBrand = (construction, index, key, isFront) => {
    const { controls } = this.state;
    const { frontRearData, rearData } = controls;
    let tyreObj = frontRearData[key][index];
    if (!isFront) {
      tyreObj = rearData[key][index];
    }
    this.setState({ isLoading: true });
    CustomerIntractionServie.getBrand({ construction: construction })
      .then((res) => {
        tyreObj.brands = res.data.data.brand;
        this.setState({
          controls,
          isLoading: false,
        });
      })
      .catch((e) => {
        let err =
          e.response && e.response.data && e.response.data.error[0]
            ? e.response.data.error[0]
            : null;
        const message = err && err.message ? err.message : "";
        ModalService.openAlert("", message, "error");
        this.setState({ isLoading: false });
      });
  };
  resetData = () => {
    let { controls } = this.state;
    controls.mobile_number.value = "";
    controls.mobile_number.touched = false;
    controls.email.value = "";
    controls.email.touched = false;
    controls.company_name.value = "";
    controls.company_name.touched = false;
    controls.attendee_name.value = "";
    controls.attendee_name.touched = false;
    controls.designation.value = "";
    controls.designation.touched = false;
    controls.whatsapp_number.value = "";
    controls.whatsapp_number.touched = false;
    controls.state.value = "";
    controls.state.touched = false;
    controls.city.value = "";
    controls.city.touched = false;
    controls.pin_code.value = "";
    controls.pin_code.touched = false;
    controls.address.value = "";
    controls.same_as_mobile.value = false;
    this.setState({
      controls,
    });
  };

  setData = async (data) => {
    let { controls } = this.state;
    controls.mobile_number.value = data.mobile_number;
    controls.email.value = data.email;
    controls.company_name.value = data.company_name;
    controls.attendee_name.value = data.attendee_name;
    controls.designation.value = data.designation;
    controls.whatsapp_number.value = data.whatsapp_number;
    controls.state.value = data.state_id;
    controls.city.value = data.city_id;
    if (data.city_id) {
      this.getCity();
    }
    controls.pin_code.value = data.pin_code;
    controls.address.value = data.address;
    await this.setState({
      controls,
    });
    return true;
  };

  render() {
    let {
      isJKuserDeatil,
      // tbbPattren,
      // tbrPattren,
      // vehicleData,
      // frontRearData, rearData,
      controls,
    } = this.state;
    return (
      <div style={{ position: "relative" }}>
        {this.state.isLoading && <CustomSpinner />}
        {!isJKuserDeatil && (
          <>
            <div>
              <Card>
                <CardBody>
                  <Row>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="mobile_number">
                          Mobile Number
                          <span className="required-field">*</span>
                        </Label>
                        <Input
                          autocomplete="nofill"
                          type="text"
                          maxLength="10"
                          onChange={(e) => this.handleInputChange(e)}
                          onBlur={this.handleOnBlurMobile}
                          name="mobile_number"
                          disabled={!this.props.is_new_business}
                          value={controls.mobile_number.value}
                          placeholder="Type here"
                        />
                        {controls.mobile_number.showErrorMsg && (
                          <div className="error">
                            * Please enter valid mobile number.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="company_name">
                          Transporter Name
                          <span className="required-field">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="company_name"
                          id="company_name"
                          value={controls.company_name.value}
                          onChange={(e) => this.handleInputChange(e)}
                          autocomplete="nofill"
                          placeholder="Type here"
                        />
                        {controls.company_name.showErrorMsg && (
                          <div className="error">
                            * Please enter transporter name.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="owner_name">
                          Customer Name
                          <span className="required-field">*</span>
                        </Label>
                        <Input
                          type="text"
                          autocomplete="nofill"
                          name="attendee_name"
                          value={controls.attendee_name.value}
                          onChange={(e) => this.handleInputChange(e)}
                          placeholder="Type here"
                        />
                        {controls.attendee_name.showErrorMsg && (
                          <div className="error">
                            * Please enter paticipant name.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="company_type">
                          Designation
                          <span className="required-field">*</span>
                        </Label>
                        <select
                          className="form-control font-size-14px"
                          value={
                            controls.designation.value
                              ? controls.designation.value
                              : ""
                          }
                          name="designation"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value="" selected disabled>
                            Select
                          </option>
                          <option value="Owner">Owner</option>
                          <option value="Manager">Manager</option>
                          <option value="Others">Others</option>
                        </select>
                        {controls.designation.showErrorMsg && (
                          <div className="error">
                            * Please select designation.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="whatsapp_number">Whatsapp Number</Label>
                        <Input
                          autocomplete="nofill"
                          type="text"
                          onChange={(e) => this.handleInputChange(e)}
                          name="whatsapp_number"
                          value={controls.whatsapp_number.value}
                          disabled={controls.same_as_mobile.value}
                          maxLength="10"
                          id="whatsapp_number"
                          placeholder="Type here"
                        />
                        {controls.whatsapp_number.showErrorMsg && (
                          <div className="error">
                            * Please enter valid whatsapp number.
                          </div>
                        )}
                        <div className="mt-1">
                          <label className="custom-checkboax1--container">
                            <input
                              type="checkbox"
                              name="same_as_mobile"
                              checked={controls.same_as_mobile.value}
                              onChange={(e) => this.handleInputChange(e)}
                            />
                            <span className="checkmark1" />
                          </label>
                          <span style={{ marginLeft: "10px" }}>
                            Same as mobile number
                          </span>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="primaryPerson">Email Address</Label>
                        <Input
                          autocomplete="nofill"
                          type="text"
                          onChange={(e) => this.handleInputChange(e)}
                          value={controls.email.value}
                          name="email"
                          id="email"
                          placeholder="Type here"
                        />
                        {controls.email.showErrorMsg && (
                          <div className="error">
                            * Please enter email address.
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row>
                    <Col xl="4" md="12" xs="12">
                      <FormGroup>
                        <Label for="address">
                          Office Address <span className="required-field">*</span>
                        </Label>
                        <TextareaAutosize
                          name="address"
                          autoComplete="nofill"
                          id="address"
                          value={controls.address.value}
                          style={{
                            minHeight: 117,
                            maxHeight: 120,
                            fontSize: "14px",
                          }}
                          className="form-control"
                          onChange={(e) =>
                            this.handleInputChange({
                              target: {
                                name: "address",
                                value: e.target.value,
                              },
                            })
                          }
                          placeholder="Type Here"
                        />
                        {controls.address.showErrorMsg && (
                          <div className="error">* Please enter office address.</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl="8" md="12" xs="12">
                      <Row>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="state">
                              State<span className="required-field">*</span>
                            </Label>
                            <select
                              className="form-control font-size-14px"
                              name="state"
                              value={controls.state.value}
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value="" selected="true" disabled>
                                Select state
                              </option>
                              {this.state.stateData.map((state, j) => (
                                <option
                                  className="text-capitalize"
                                  value={state.state_id}
                                >
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {controls.state.showErrorMsg && (
                              <div className="error">
                                * Please select state.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="city">
                              City<span className="required-field">*</span>
                            </Label>
                            <select
                              className="form-control font-size-14px"
                              name="city"
                              value={controls.city.value}
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value="" selected="true" disabled>
                                Select city
                              </option>
                              {this.state.cityData.map((city, j) => (
                                <option
                                  className="text-capitalize"
                                  value={city.city_id}
                                >
                                  {city.name}
                                </option>
                              ))}
                            </select>
                            {controls.city.showErrorMsg && (
                              <div className="error">* Please select city.</div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col xl="6" md="12" xs="12">
                          <FormGroup>
                            <Label for="pin">
                              Pincode
                              <span className="required-field">*</span>
                            </Label>
                            <Input
                              autocomplete="nofill"
                              type="text"
                              name="pin_code"
                              maxLength="6"
                              value={controls.pin_code.value}
                              onChange={(e) => this.handleInputChange(e)}
                              id="pin_code"
                              placeholder="Type here"
                            />
                            {controls.pin_code.showErrorMsg && (
                              <div className="error">
                                * Please enter pincode .
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col
                          xl="6"
                          md="12"
                          xs="12"
                          className="text-align-right d-flex"
                        >
                          <Button
                            className="ml-auto mt-auto btn-pill action-button font-weight-bold mb-3"
                            color="warning"
                            style={{ width: "137px" }}
                            onClick={() => this.submitDetail(true)}
                          >
                            {this.props.isEditData ?"Update" : "Submit"}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <ConfirmationNextAtendee
              ref="refConfirmationNextAtendee"
              submitDetail={this.submitDetail}
              from=""
            />
            <CustomerIntractionAddEditVehicle
              ref="refCustomerIntractionAddEditVehicle"
              submitVehclies={this.submitVehclies}
              from=""
            />
          </>
        )}
      </div>
    );
  }
}

export default CipBusinessDetail;
